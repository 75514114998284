<!-- eslint-disable-next-line vue/valid-template-root -->
<template></template>
<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import pwaManifest from './pwa-manifest.webmanifest'
// without '?file' webpack tries to inline into base64, since the files are relatevely small
import PGFaviconWhite32 from './icons/PG-favicon-white-32.webp?file'
import PGFaviconWhite48 from './icons/PG-favicon-white-48.webp?file'
import PGFaviconWhite96 from './icons/PG-favicon-white-96.webp?file'
import PGFaviconWhite144 from './icons/PG-favicon-white-144.webp?file'
import PGFaviconWhite180 from './icons/PG-favicon-white-180.webp?file'
import PGFaviconWhite192 from './icons/PG-favicon-white-192.webp?file'
import PGFaviconWhite512 from './icons/PG-favicon-white-512.webp?file'
import PGFaviconWhiteSVG from './icons/PG-favicon-white.svg?file'
import PGFaviconBlackICO from './icons/PG-favicon-black.ico?file'
import PGFaviconBlack32 from './icons/PG-favicon-black-32.webp?file'
import PGFaviconBlack48 from './icons/PG-favicon-black-48.webp?file'
import PGFaviconBlack96 from './icons/PG-favicon-black-96.webp?file'
import PGFaviconBlack144 from './icons/PG-favicon-black-144.webp?file'
import PGFaviconBlack180 from './icons/PG-favicon-black-180.webp?file'
import PGFaviconBlack192 from './icons/PG-favicon-black-192.webp?file'
import PGFaviconBlack512 from './icons/PG-favicon-black-512.webp?file'
import PGFaviconBlackSVG from './icons/PG-favicon-black.svg?file'
import PGFaviconWhiteICO from './icons/PG-favicon-white.ico?file'
import PGFaviconMaskSVG from './icons/PG-favicon-mask.svg?file'
import PlaygamaOG from './icons/Playgama-OG.webp?file'

const iconsMap = {
    PGFaviconWhite32,
    PGFaviconWhite48,
    PGFaviconWhite96,
    PGFaviconWhite144,
    PGFaviconWhite180,
    PGFaviconWhite192,
    PGFaviconWhite512,
    PGFaviconWhiteSVG,
    PGFaviconWhiteICO,
    PGFaviconBlack32,
    PGFaviconBlack48,
    PGFaviconBlack96,
    PGFaviconBlack144,
    PGFaviconBlack180,
    PGFaviconBlack192,
    PGFaviconBlack512,
    PGFaviconBlackSVG,
    PGFaviconBlackICO,
}

const { t } = useI18n()
const route = useRoute()

const organizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    '@id': 'https://playgama.com/#organization',
    url: 'https://playgama.com',
    name: 'Playgama',
    description: t('description'),
    logo: PGFaviconWhiteSVG,
    brand: {
        '@type': 'Brand',
        '@id': 'https://playgama.com',
        name: 'Playgama',
        slogan: t('slogan'),
    },
    email: 'support@playgama.com',
    sameAs: [
        'https://www.facebook.com/playgamagames',
        'https://www.linkedin.com/company/playgama',
        'https://x.com/playgamacom',
    ],
}

const adaptiveIcons = (() => {
    const sizes = [
        ['ICO', 'image/x-icon'],
        ['SVG', 'image/svg+xml'],
        [32, 'image/webp'],
        [48, 'image/webp'],
        [96, 'image/webp'],
        [144, 'image/webp'],
        [180, 'image/webp'],
        [192, 'image/webp'],
        [512, 'image/webp'],
    ] as const
    const colorSchemes = ['dark', 'light'] as const
    const res: { rel: string; type: string; sizes?: string; href: string; media: string }[] = []
    sizes.forEach(([size, type]) => {
        colorSchemes.forEach((colorScheme) => {
            const color = colorScheme === 'dark' ? 'Black' : 'White'
            const newSizes = typeof size === 'number' ? `${size}x${size}` : undefined
            res.push({
                rel: 'icon',
                type,
                sizes: newSizes,
                href: iconsMap[`PGFavicon${color}${size}`],
                media: `(prefers-color-scheme:${colorScheme})`,
            })
        })
    })
    return res
})()

const pageMeta = computed(() => {
    const meta = [
        {
            name: 'description',
            content: t('description'),
        },
        {
            name: 'validation-token',
            content: 'hb42hn0e5uyz0bfp',
        },
        {
            name: 'color-scheme"',
            content: 'dark',
        },

        // Progressive Web App
        {
            name: 'mobile-web-app-capable',
            content: 'yes',
        },
        {
            name: 'apple-mobile-web-app-capable',
            content: 'yes',
        },
        {
            name: 'apple-mobile-web-app-status-bar-style',
            content: 'black-translucent',
        },
        {
            name: 'theme-color',
            content: '#000000',
        },
        // temporary OG image
        // TODO better OG card
        {
            property: 'og:image',
            content: PlaygamaOG,
        },
        {
            property: 'og:image:alt',
            content: t('alt_image'),
        },
        {
            property: 'og:title',
            content: t('title'),
        },
        {
            property: 'og:description',
            content: t('description'),
        },
        {
            property: 'og:type',
            content: 'website',
        },
        {
            property: 'og:url',
            content: `${process.env.VUE_APP_HOST_URL}${route.fullPath === '/' ? '' : route.fullPath}`,
        },
        {
            property: 'og:site_name',
            content: 'Playgama.com',
        },
        {
            name: 'twitter:card',
            content: 'summary',
        },
        {
            name: 'twitter:title',
            content: t('title'),
        },
        {
            name: 'twitter:description',
            content: t('description'),
        },
        {
            name: 'twitter:image',
            content: PlaygamaOG,
        },
        {
            name: 'twitter:image:alt',
            content: t('alt_image'),
        },
    ]
    if (process.env.ROBOTS_DISALLOW === 'DISALLOW') {
        meta.push({
            name: 'robots',
            content: 'noindex',
        })
    }
    return {
        title: t('title'),
        meta,
        link: [
            {
                rel: 'manifest',
                href: pwaManifest,
            },
            ...adaptiveIcons,
            {
                rel: 'mask-icon',
                href: PGFaviconMaskSVG,
                color: '#9747FF',
                media: '(prefers-color-scheme:light)',
            },
            {
                rel: 'mask-icon',
                href: PGFaviconMaskSVG,
                color: '#AA76FF',
                media: '(prefers-color-scheme:dark)',
            },
            {
                rel: 'apple-touch-icon',
                sizes: '180x180',
                href: PGFaviconWhite180,
                media: '(prefers-color-scheme:light)',
            },
            {
                rel: 'apple-touch-icon',
                sizes: '180x180',
                href: PGFaviconBlack180,
                media: '(prefers-color-scheme:dark)',
            },
        ],
        script: [
            {
                type: 'application/ld+json',
                innerHTML: organizationSchema,
            },
        ],
    }
})
useHead(pageMeta)
</script>
<i18n lang="json">
{
    "en": {
        "title": "Free Online Games Now on Playgama 🎮 Play NOW!",
        "slogan": "Free Games Online",
        "alt_image": "Free Games Online",
        "description": "Dive into the best online games at Playgama! 🎮 Play, earn, and score real-world rewards — no joke! Start your adventure now on desktop, mobile, or even that tablet you forgot you had!🕹️"
    }
}
</i18n>
