<template>
    <div
        v-if="task"
        :class="$style.task"
    >
        <div :class="$style.task_content">
            <div :class="$style.fennecs">
                <Icon name="fennek_coin_small" />
                <Typography
                    type="header"
                    size="s"
                    active
                    :responsive="false"
                >
                    {{ task.coins }}
                </Typography>
            </div>
            <Typography
                type="paragraph"
                size="xs"
                :class="$style.description"
                :responsive="false"
            >
                {{ task.description }}
            </Typography>
        </div>
        <Button
            v-if="task.status === 'ACTIVE'"
            :size="widgetAppearance.buttonSize"
            :color="widgetAppearance.buttonColor"
            :link="task.gameLink"
            @click="onPlay"
        >
            {{ task.buttonLabel }}
        </Button>
        <Button
            v-else
            color="golden"
            :size="widgetAppearance.buttonSize"
            @click="onClaim"
        >
            {{ t('claim') }}
        </Button>
    </div>
</template>

<script setup lang="ts">
import { computed, type ComputedRef } from 'vue'

import type {
    DailyTaskDto,
    GameTaskDto,
    RandomTaskDto,
    ReferralTaskDto,
    ShortGameType,
    TaskDto,
    TaskStatus,
    TaskType,
} from '@/types'
import Button from '@/components_new/Button.vue'
import { useI18n } from 'vue-i18n'
import Typography from '@/components_new/Typography.vue'

import Icon from '@/components_new/Icon/Icon.vue'
import { useCategoriesStore } from '@/store/categories-store'
import { storeToRefs } from 'pinia'
import { tasksTranslation } from '@/components_new/TaskItems/utils'

interface TaskProps {
    task: TaskDto
    appearance?: 'default' | 'accent'
}

type Task = {
    id: string
    status: TaskStatus
    coins: number
    description: string
    buttonLabel: string
    gameLink?: string
    // we might not need this game key anywhere
    game?: ShortGameType
    type: TaskType
}

type WidgetAppearance = {
    buttonColor: 'purple-400' | 'carbon-400'
    buttonSize: 'm' | 'l'
}

const props = withDefaults(defineProps<TaskProps>(), {
    appearance: 'default',
})

const emit = defineEmits<{ claimTask: [taskId: string]; taskStart: [taskId: string] }>()
const { t } = useI18n({ messages: tasksTranslation })
const widgetAppearance: ComputedRef<WidgetAppearance> = computed(() => {
    if (props.appearance === 'accent') {
        return {
            buttonColor: 'purple-400',
            buttonSize: 'm',
        }
    }

    return {
        buttonColor: 'carbon-400',
        buttonSize: 'm',
    }
})

const categoriesStore = useCategoriesStore()

const { randomGameHRU } = storeToRefs(categoriesStore)

function getDailyBonusTask(task: DailyTaskDto): Task {
    const { status, coins, task_id, type } = task
    return {
        status,
        coins,
        id: task_id,
        type,
        buttonLabel: t('claim'),
        description: t('daily_bonus_description'),
    }
}

function getReferralTask(task: ReferralTaskDto): Task {
    const {
        status,
        coins,
        task_id,
        type,
        settings: { invitations_not_claimed },
    } = task
    const coinsPerFriend = invitations_not_claimed > 1 ? Math.floor(coins / invitations_not_claimed) : coins
    const description =
        invitations_not_claimed > 1
            ? t('collect_invite_widget_many', { friends: invitations_not_claimed, coins: coinsPerFriend })
            : t('collect_invite_widget', { coins: coinsPerFriend })
    return {
        status,
        coins,
        id: task_id,
        type,
        buttonLabel: t('claim'),
        description,
    }
}

function getRandomGameTask(task: RandomTaskDto): Task {
    const { status, coins, settings, task_id, type } = task
    const min = Math.ceil(settings.seconds_to_play / 60)
    return {
        status,
        type,
        id: task_id,
        coins,
        gameLink: `/game/${randomGameHRU.value}`,
        description: t('random_game_description', { min }),
        buttonLabel: t('play'),
    }
}

function getGameTask(task: GameTaskDto): Task {
    const {
        status,
        coins,
        settings: { game, seconds_to_play },
        task_id,
        type,
    } = task
    const min = Math.ceil(seconds_to_play / 60)
    return {
        status,
        type,
        coins,
        id: task_id,
        gameLink: `/game/${game?.hru}`,
        game,
        description: game.title,
        buttonLabel: t('button_label', { min }),
    }
}

const task = computed(() => {
    if (props.task.type === 'VISIT_EVERYDAY') {
        return getDailyBonusTask(props.task)
    }

    if (props.task.type === 'RANDOM_GAME') {
        return getRandomGameTask(props.task)
    }

    if (props.task.type === 'PLAY_GAME') {
        return getGameTask(props.task)
    }
    return getReferralTask(props.task)
})

function onClaim() {
    emit('claimTask', task.value.id)
}

function onPlay() {
    emit('taskStart', task.value.id)
}
</script>

<style module>
.task {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    background-color: var(--carbon-300);
}

.task_content {
    display: inline-block;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fennecs {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 6px;
    margin-bottom: 4px;
}

.description {
    color: rgba(255, 255, 255, 0.6);
}
</style>
