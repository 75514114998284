<!-- eslint-disable vuejs-accessibility/media-has-caption -->
<template>
    <CardContainer
        :is="props.game ? BaseLink : 'div'"
        :appearance="props.appearance"
        :class="$style.gameCard"
        :alt="props.game?.title"
        :src="props.game?.preview"
        :responsive="props.responsive"
        :to="gameLink"
        :target="props.targetLink"
        itemscope
        itemtype="https://schema.org/VideoGame"
        itemprop="url"
        @mouseenter="showVideo"
        @focus="showVideo"
    >
        <video
            v-if="isVideoAllowed && gameVideo"
            :class="$style.video"
            :src="gameVideo"
            loop
            muted
            playsInline
            autoplay
            itemprop="video"
        />
        <Typography
            v-if="props.game"
            size="s"
            accent
            :responsive="false"
            itemprop="name"
            :class="$style.title"
        >
            {{ props.game.title }}
        </Typography>
    </CardContainer>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { ShortGameType } from '@/types'
import CardContainer from '@/components_new/CardContainer.vue'
import Typography from '@/components_new/Typography.vue'
import BaseLink from '@/components_new/BaseLink.vue'

interface GameCardProps {
    game?: ShortGameType
    appearance: 'big' | 'medium'
    responsive?: boolean
    targetLink?: '_blank'
}

const props = defineProps<GameCardProps>()

const isVideoAllowed = ref(false)
const gameLink = computed(() => `/game/${props.game?.hru}`)
const gameVideo = computed(() => props.game?.video)

function showVideo() {
    isVideoAllowed.value = true
}
</script>

<style module>
.title,
.video {
    display: none;
}

.gameCard:hover .video {
    display: block;
    grid-area: main;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gameCard:hover .title {
    display: block;
    grid-area: main;
    padding: 8px 12px 8px;
    align-self: end;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
}
</style>
