<template>
    <HeadMeta />
    <RouterView v-if="pageStatus === 200 || manuallyHandledError" />
    <ErrorPage
        v-else-if="pageStatus === 404"
        :type="404"
    />
    <ErrorPage
        v-else-if="pageStatus >= 500"
        :type="500"
    />
    <ModalSignIn />
    <!-- :key=$route.path helps to refresh the sticky on every meaningful navigation (ignoring query change like on categories) -->
    <AdBlock
        v-if="!layoutStore.fullScreen && platformFromStore !== 'desktop'"
        :key="$route.path"
        type="sticky_portrait"
    />
</template>
<script lang="ts" setup>
// необходимый для SSR и правильных статусов ответов враппер
import { computed, onBeforeMount, onMounted, onServerPrefetch, onUnmounted, ref, useSSRContext } from 'vue'
import { storeToRefs } from 'pinia'
import { type RouteLocationNormalized, useRoute, useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useAppStore } from '@/store/app-store'
import { useAdsStore } from '@/store/ads-store'
import { useLoggerStore } from '@/store/logger-store'
import { getCurrentPlatform } from '@/utils/helpers'
import { GameProviders, type PlatformType } from '@/types'
import { useUserStore } from '@/store/user-store/user-store'
import { useLayoutStore } from '@/store/layout-store'
import ErrorPage from '@/pages/Error/ErrorPage.vue'
import AdBlock from '@/components_new/AdBlock.vue'
import { getUACFromCookies } from '@/utils/cookie-parser'
import ModalSignIn from '@/components_new/ModalItems/components/ModalSignIn.vue'
import HeadMeta from '@/components_new/HeadMeta/HeadMeta.vue'
import { LOGIN_ATTEMPT_KEY, DEFAULT_CLIDS } from '@/constants/general'
import { ROUTE_NAMES } from '@/router/constants'
import { GAM_SOURCE } from '@/modules/google-ads'

const appStore = useAppStore()
const adsStore = useAdsStore()
const layoutStore = useLayoutStore()
const loggerStore = useLoggerStore()
const $router = useRouter()
const $route = useRoute()

const { pageStatus, platform: platformFromStore } = storeToRefs(appStore)

const userStore = useUserStore()

const CLID_PARAM_NAME = 'clid'
const WIDGET_ID_PARAM_NAME = 'widget_id'
const GD_GAME_ID_KEY = 'gd_game_id'
const GD_KEY = 'use_gd_ads'
const DISABLED_ADS = 'use_no_ads'
const taskUpdateTimeOutMs = Number(process.env.VUE_APP_USER_TASKS_POLLING_MS) || 60000

function getPageScripts(route: typeof $route) {
    const scripts = []
    if (!route.query[GD_KEY]) {
        scripts.push(
            // gtm
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '${process.env.VUE_APP_GA_MEASUREMENT_ID}');`,
        )
    }

    return scripts
}

const manuallyHandledError = computed(() => $route.meta.manuallyHandledErrors)

// предзаданные параметры страницы
useHead(
    computed(() => ({
        script: getPageScripts($route),
        link: !$route.query[GD_KEY]
            ? [
                  {
                      href: GAM_SOURCE,
                      rel: 'preload',
                      as: 'script',
                  },
              ]
            : [],
    })),
)

function getPageInfo(page: RouteLocationNormalized) {
    return `${page.name?.toString()} ${page.params.game || page.params.category || ''}`
}

const ctx = useSSRContext()
onServerPrefetch(() => {
    if (ctx?.pageStatus) {
        appStore.setPageStatus(ctx.pageStatus)
    } else {
        appStore.$reset()
        appStore.setPlatform(ctx?.platform as PlatformType)
    }
})

onBeforeMount(() => {
    // кажется тут пора навести порядок. пока хороших идей нет,
    // но пусть этот комментарий на них натолкнет когда-нибудь
    let currentPageSessionStartedAt = Date.now()
    const currentSessionStartedAt = Date.now()
    let currentPageData = getPageInfo($route)

    $router.beforeEach((to) => {
        appStore.setPageStatus(200)

        const delta = Date.now() - currentPageSessionStartedAt
        loggerStore.logEvent({
            event: 'user_time',
            eventName: 'navigation',
            label: delta / 1000,
            action: currentPageData,
            pageName: ($route.name as string) || undefined,
        })

        currentPageSessionStartedAt = Date.now()
        currentPageData = getPageInfo(to)
        // reset content_category for upcoming page to avoid misleading content_category
        adsStore.updateTargeting({ content_category: '' })
        if (to.name === ROUTE_NAMES.GAME_PAGE || to.name === ROUTE_NAMES.EXPORT_GAME_PAGE) {
            // for now here and below `as string` helps us to fit the types
            // TODO: maybe we could just do a typeof _ === 'string' check if we are totally sure
            adsStore.updateTargeting({ hru: to.params.game, page: $route.name as string })
        } else {
            // for now here and above `as string` helps us to fit the types
            // TODO: maybe we could just do a typeof _ === 'string' check if we are totally sure
            adsStore.updateTargeting({ hru: '', page: $route.name as string })
        }
    })

    window.addEventListener('beforeunload', () => {
        const delta = Date.now() - currentPageSessionStartedAt
        const sessionTime = Date.now() - currentSessionStartedAt
        loggerStore.logEvent({
            event: 'user_time',
            eventName: 'navigation',
            label: delta / 1000,
            action: currentPageData,
            pageName: ($route.name as string) || undefined,
        })

        loggerStore.logEvent({
            event: 'user_time',
            eventName: 'session_end',
            label: sessionTime / 1000,
            action: currentPageData,
            pageName: ($route.name as string) || undefined,
        })
    })

    const ua = window.navigator.userAgent
    const platform = getCurrentPlatform(ua)
    appStore.setPlatform(platform)

    const isGD = !!$route.query[GD_KEY]
    const areAdsDisabled = !!$route.query[DISABLED_ADS] && $route.name === ROUTE_NAMES.EXPORT_GAME_PAGE
    const fallbackClid = window.location.host === 'playgama.com' ? DEFAULT_CLIDS.direct : DEFAULT_CLIDS.directTest
    const clid = ($route.query[CLID_PARAM_NAME] as string) || fallbackClid
    const widgetId = $route.query[WIDGET_ID_PARAM_NAME] as string | undefined
    appStore.setEnv({
        clid,
        widgetId,
    })
    const GD_GAME_ID = $route.query[GD_GAME_ID_KEY]
    if (GD_GAME_ID) {
        window.gd_id = GD_GAME_ID as string
    }

    const userAcquisitionParams = getUACFromCookies(document.cookie)

    const gamePageTargeting: Record<string, string> = {}

    if ($route.name === ROUTE_NAMES.GAME_PAGE || $route.name === ROUTE_NAMES.EXPORT_GAME_PAGE) {
        gamePageTargeting.hru = $route.params.game as string
    }

    let configKey = GameProviders.GOOGLE_AD
    if (isGD) {
        configKey = GameProviders.GAME_DISTRIBUTION
    }
    if (areAdsDisabled) {
        configKey = GameProviders.EMPTY_PROVIDER
    }

    adsStore.setAdServiceConfig({
        configKey,
        targeting: {
            playgama_clid: clid,
            widget_id: widgetId || '',
            ...userAcquisitionParams,
            ...gamePageTargeting,
            page: $route.name as string,
        },
        logEvent: loggerStore.logEvent,
        route: $route,
        preloadGameAd: $route.name === ROUTE_NAMES.EXPORT_GAME_PAGE,
    })

    if (
        Object.keys(userAcquisitionParams).length > 0 && // paid user
        adsStore.adMediator && // service is available
        $route.name !== ROUTE_NAMES.GAME_PAGE &&
        $route.name !== ROUTE_NAMES.EXPORT_GAME_PAGE // not a game page
    ) {
        // show fullscreen ads for paid users at not game pages
        adsStore.adMediator.prepareAd({ type: 'fullscreen' })
    }

    const conversionValue = window.additionalServerData?.conversionValue

    if (conversionValue !== undefined) {
        window.dataLayer.push({
            conversion_value: conversionValue,
        })
    }
})

const tasksPollingTimeoutId = ref<ReturnType<typeof setTimeout> | null>(null)
function pollTasks() {
    if (!document.hidden) {
        userStore.getUserTasks()
    }
    tasksPollingTimeoutId.value = setTimeout(pollTasks, taskUpdateTimeOutMs)
}

onMounted(async () => {
    if ($route.name !== ROUTE_NAMES.EXPORT_GAME_PAGE) {
        const user = await userStore.getUser()
        loggerStore.logEvent({
            event: 'custom_event',
            eventName: 'session_start',
            pageName: $route.name as string,
            clid: appStore.clid,
            label: '',
            action: '',
        })
        if (user) {
            window.dataLayer.push({
                user_id: user._id,
            })
        }
        loggerStore.startKeyEventsCountdown()

        // don't need watch as authorization refetch the page
        if (user) {
            pollTasks()
            const wasThereLoginAttempt = window.sessionStorage.getItem(LOGIN_ATTEMPT_KEY)
            if (wasThereLoginAttempt) {
                loggerStore.logEvent({
                    event: 'custom_event',
                    eventName: 'login',
                    label: '',
                    action: 'success',
                })
                window.sessionStorage.removeItem(LOGIN_ATTEMPT_KEY)
            }
        }
    }
    // this enables css :active pseudo-class on touch devices
    const noop = () => {}
    document.body.addEventListener('touchstart', noop, false)
})

onUnmounted(() => {
    if (tasksPollingTimeoutId.value) {
        clearTimeout(tasksPollingTimeoutId.value)
    }
})
</script>
