<template>
    <component
        :is="link ? BaseLink : 'button'"
        :to="link"
        :target="target"
        :class="[
            $style.button,
            $style[`size_${props.size}`],
            $style[`width_${props.width}`],
            $style[`effect_${props.effect}`],
            $style[`visual_${props.visual}`],
            $style[`color_${props.color}`],
            props.disabled ? $style.disabled : '',
        ]"
        :type="props.type"
        :disabled="props.disabled"
        @click="emit('click', $event)"
    >
        <div :class="$style.content">
            <Icon
                v-if="props.iconLeft"
                :name="props.iconLeft"
                :class="$style.iconLeft"
            />
            <Typography
                v-if="$slots.default"
                type="label"
                :accent="true"
                :size="labelSizesMap[props.size]"
                :responsive="false"
            >
                <slot />
            </Typography>
            <Icon
                v-if="props.iconRight"
                :name="props.iconRight"
                :class="$style.iconRight"
            />
            <Icon
                v-if="props.icon"
                :name="props.icon"
                :class="$style.iconCenter"
            />
        </div>
    </component>
</template>

<script lang="ts" setup>
import { type RouteLocationRaw } from 'vue-router'
import Icon from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import { type IconNameType } from '@/components_new/Icon'

interface ButtonProps {
    type?: 'button' | 'submit'
    disabled?: boolean
    size?: 's' | 'm' | 'l'
    width?: 'normal' | 'full'
    visual?: 'fill' | 'outline' | 'text' | 'ghost'
    color?:
        | 'purple-400'
        | 'golden'
        | 'white'
        | 'white-60'
        | 'carbon-300'
        | 'carbon-400'
        | 'purple-500'
        | 'purple-600'
        | 'lime'
        | 'rose-400'
    effect?: 'bevel' | 'no'
    target?: '_self' | '_blank'
    link?: string | RouteLocationRaw
    iconLeft?: IconNameType
    iconRight?: IconNameType
    icon?: IconNameType
}

const props = withDefaults(defineProps<ButtonProps>(), {
    type: 'button',
    disabled: false,
    size: 'm',
    width: 'normal',
    visual: 'fill',
    color: 'purple-400',
    effect: 'no',
})

const emit = defineEmits<{ click: [event: Event] }>()

const labelSizesMap = {
    xs: 'xs',
    s: 's',
    m: 's',
    l: 'm',
} as const
</script>

<style module>
/* Base button style */
.button {
    display: inline-block;
    line-height: 100%;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    border-radius: var(--border-radius);
    --border-width: 2px;
    --border-radius: 1000px;
    --focus-accent: var(--electric-lime);
}

/* Color themes */

.color_purple-400 {
    --text: #fff;
    --main: var(--accent-purple);
    --main-ghost-active: #c0ff47;
    --main-outline: #c7abff;
}

.color_purple-500 {
    --text: #fff;
    --main: #aa76ff;
    --main-ghost-active: #c0ff47;
    --main-outline: #c7abff; /* add for backward compatibility for outline-buttons */
}

.color_purple-600 {
    --text: #fff;
    --main: #c7abff;
    --main-ghost-active: #c0ff47;
    --main-outline: #c7abff; /* add for backward compatibility for outline-buttons */
}

.color_golden {
    --text: #000;
    --main: #ffc850;
    --main-ghost-active: #ffc850;
}

.color_white {
    --text: #000;
    --main: #ffffff;
    --main-ghost-active: #aa76ff;
    --main-outline: rgba(255, 255, 255, 0.6);
}

.color_white-60 {
    --text: #000;
    --main: rgba(255, 255, 255, 0.6);
    --main-outline: rgba(255, 255, 255, 0.6);
}

.color_carbon-300 {
    --text: var(--purple-500);
    --main: var(--carbon-300);
    --main-ghost-active: #d8bbff;
}

.color_carbon-400 {
    --text: #fff;
    --main: var(--carbon-400);
    --main-ghost-active: #d8bbff;
}

.color_lime {
    --text: var(--black);
    --main: var(--electric-lime);
    --focus-accent: var(--accent-purple);
}

.color_rose-400 {
    --text: var(--black);
    --main: var(--rose-400);
    --focus-accent: var(--accent-purple);
}

/* Base styles */

.content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.button:before,
.button:after {
    content: '';
    position: absolute;
    inset: 0;
    pointer-events: none;
    border-radius: var(--border-radius);
}

.button:before {
    background: var(--background-color, transparent);
}

.button:after {
    border: var(--border-width) solid var(--border-color, transparent);
}

.button:focus-visible {
    outline: none;
    --border-color: var(--focus-accent, var(--border));
}

.disabled {
    cursor: not-allowed;
}

/* TODO check normalize.css for button color style - remove selector .button */

/* Visuals */

.button.visual_fill {
    color: var(--text);
    --background-color: var(--main);
}

.button.visual_fill:not(.disabled):hover:before {
    filter: brightness(1.08);
}
.button.visual_fill:not(.disabled):active:before {
    filter: brightness(0.92);
}

/* color-theme independent */
.button.visual_fill.disabled {
    color: rgba(255, 255, 255, 0.4);
    --background-color: var(--carbon-400);
}

.button.visual_outline {
    color: var(--main-outline);
    --border-color: var(--main-outline);
}

.button.visual_outline:not(.disabled):hover,
.button.visual_outline:not(.disabled):active {
    color: var(--text);
    --background-color: var(--main);
    --border-color: transparent;
}
.button.visual_outline:not(.disabled):hover:before {
    filter: brightness(1.08);
}
.button.visual_outline:not(.disabled):active:before {
    filter: brightness(0.92);
}

/* color-theme independent */
.button.visual_outline.disabled {
    color: rgba(255, 255, 255, 0.4);
    --border-color: var(--carbon-400);
}

.button.visual_text {
    color: var(--main-outline);
}

.button.visual_text:not(.disabled):hover,
.button.visual_text:not(.disabled):active {
    color: var(--text);
    --background-color: var(--main);
}
.button.visual_text:not(.disabled):hover:before {
    filter: brightness(1.08);
}
.button.visual_text:not(.disabled):active:before {
    filter: brightness(0.92);
}

/* color-theme independent */
.button.visual_text.disabled {
    color: rgba(255, 255, 255, 0.4);
}

.button.visual_ghost {
    color: var(--main);
    --background-color: transparent;
}

.button.visual_ghost:not(.disabled):hover,
.button.visual_ghost:not(.disabled):active,
.button.visual_ghost:not(.disabled):focus-visible {
    color: var(--main-ghost-active);
}

.button.color_carbon-300:not(.disabled):hover,
.button.color_carbon-300:not(.disabled):active,
.button.color_carbon-300:not(.disabled):focus-visible {
    color: var(--black);
    --main: var(--electric-lime);
}

/* color-theme independent */
.button.visual_ghost.disabled {
    color: rgba(255, 255, 255, 0.4);
}

/* Sizes */

.size_s {
    height: 24px;
    padding: 6px 12px;
    --border-radius: 12px;
}
.size_s .content {
    gap: 4px;
}
.size_s .iconLeft,
.size_s .iconRight {
    width: 12px;
    height: 12px;
}
.size_s .iconCenter {
    width: 16px;
    height: 16px;
}
.size_s:has(.iconCenter) {
    padding: 4px;
}

.size_m {
    height: 32px;
    padding: 6px 12px;
    --border-radius: 16px;
}
.size_m .content {
    gap: 6px;
}
.size_m .iconLeft,
.size_m .iconRight,
.size_m .iconCenter {
    width: 20px;
    height: 20px;
}
.size_m:has(.iconLeft) {
    padding-left: 8px;
}
.size_m:has(.iconRight) {
    padding-right: 8px;
}
.size_m:has(.iconCenter) {
    padding: 6px;
}

.size_l {
    height: 48px;
    padding: 12px 20px;
    --border-radius: 24px;
}
.size_l .content {
    gap: 8px;
}
.size_l .iconLeft,
.size_l .iconRight,
.size_l .iconCenter {
    width: 24px;
    height: 24px;
}
.size_l:has(.iconLeft) {
    padding-left: 12px;
    padding-right: 16px;
}
.size_l:has(.iconRight) {
    padding-right: 12px;
    padding-left: 16px;
}
.size_l:has(.iconCenter) {
    padding: 12px;
}

/* Width */

.width_full {
    width: 100%;
}

/* Effects */

.effect_bevel:before {
    box-shadow:
        inset 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.32),
        0px 1.5px 1px 0px rgba(0, 0, 0, 0.32);
}
</style>
