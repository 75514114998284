<template>
    <component
        :is="props.is"
        :class="[
            $style.typography,
            { [$style.accent]: accent },
            { [$style.responsive]: responsive },
            $style[className],
        ]"
    >
        <slot />
    </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface TypographyProps {
    type?: 'paragraph' | 'header' | 'label' | 'caption' | 'caption_all-caps'
    size?: 'xxxl' | 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs'
    accent?: boolean
    responsive?: boolean
    is?: keyof HTMLElementTagNameMap
}

const props = withDefaults(defineProps<TypographyProps>(), {
    responsive: true,
    is: 'span',
})

const componentTypeMap: Record<string, string> = {
    h1: 'header',
    h2: 'header',
    h3: 'header',
    h4: 'header',
    h5: 'header',
    h6: 'header',
    h7: 'header',
    label: 'caption',
    p: 'paragraph',
    span: 'paragraph',
}

const className = computed(() => {
    let type = ''
    if (props.type) {
        type = props.type
    } else if (props.is) {
        const isKey = props.is
        type = componentTypeMap[isKey] ?? ''
    }
    return props.size ? `${type}_${props.size}` : type
})
</script>

<style module>
.typography {
    font-family: var(--font-family);
    font-weight: var(--font-weight-default);
}

.accent {
    font-weight: var(--font-weight-accent);
}

.caption_xs.accent {
    font-weight: var(--font-weight-caption-xs);
}

/* Invalid */

.paragraph_xxxl,
.paragraph_xxl,
.paragraph_xl,
.label_xxxl,
.label_xxl,
.label_xl,
.caption_xxxl,
.caption_xxl,
.caption_xl,
.caption_all-caps_xxxl,
.caption_all-caps_xxl,
.caption_all-caps_xl,
.caption_all-caps_l,
.caption_all-caps_m,
.caption_all-caps_s,
.caption_all-caps_xs {
    color: red;
}

/* Paragraph */

.paragraph_l {
    font-size: var(--font-size-paragraph-l);
    line-height: var(--line-height-paragraph-l);
    letter-spacing: var(--letter-spacing-paragraph-l);
}

.paragraph_m {
    font-size: var(--font-size-paragraph-m);
    line-height: var(--line-height-paragraph-m);
    letter-spacing: var(--letter-spacing-paragraph-m);
}
.paragraph_s {
    font-size: var(--font-size-paragraph-s);
    line-height: var(--line-height-paragraph-s);
    letter-spacing: var(--letter-spacing-paragraph-s);
}

.paragraph_xs {
    font-size: var(--font-size-paragraph-xs);
    line-height: var(--line-height-paragraph-xs);
    letter-spacing: var(--letter-spacing-paragraph-xs);
}

/* Label */
.label_l {
    font-size: var(--font-size-label-l);
    line-height: var(--line-height-label);
}
.label_m {
    font-size: var(--font-size-label-m);
    line-height: var(--line-height-label);
}
.label_s {
    font-size: var(--font-size-label-s);
    line-height: var(--line-height-label);
}

.label_xs {
    font-size: var(--font-size-label-xs);
    line-height: var(--line-height-label);
}

/* Caption */

.caption_xs {
    font-size: var(--font-size-caption-xs);
    line-height: var(--line-height-caption-xs);
    letter-spacing: var(--letter-spacing-caption-xs);
}

.caption_s {
    font-size: var(--font-size-caption-s);
    line-height: var(--line-height-caption-s);
    letter-spacing: var(--letter-spacing-caption-s);
}

.caption_m {
    font-size: var(--font-size-caption-m);
    line-height: var(--line-height-caption-m);
    letter-spacing: var(--letter-spacing-caption-m);
}

.caption_all-caps {
    font-size: var(--font-size-caption-all-caps);
    font-weight: var(--font-weight-accent);
    line-height: var(--line-height-caption-all-caps);
    letter-spacing: var(--letter-spacing-caption-all-caps);
    text-transform: uppercase;
}

/* HEADER */

.header_xxxl {
    font-size: var(--font-size-header-xxxl);
    font-weight: var(--font-weight-header-xxxl);
    line-height: var(--line-height-header);
}
.header_xxl {
    font-size: var(--font-size-header-xxl);
    font-weight: var(--font-weight-header-xxl);
    line-height: var(--line-height-header);
}
.header_xl {
    font-size: var(--font-size-header-xl);
    font-weight: var(--font-weight-header-xl);
    line-height: var(--line-height-header);
}
.header_l {
    font-size: var(--font-size-header-l);
    font-weight: var(--font-weight-header-l);
    line-height: var(--line-height-header);
}
.header_m {
    font-size: var(--font-size-header-m);
    font-weight: var(--font-weight-header-m);
    line-height: var(--line-height-header);
}
.header_s {
    font-size: var(--font-size-header-s);
    font-weight: var(--font-weight-header-s);
    line-height: var(--line-height-header);
}

.header_xs {
    font-size: var(--font-size-header-xs);
    font-weight: var(--font-weight-header-xs);
    line-height: var(--line-height-header);
}

@media (--tablet-plus) {
    .responsive.label_xs {
        font-size: var(--font-size-label-s);
    }
    .responsive.label_s {
        font-size: var(--font-size-label-m);
    }
    .responsive.label_m {
        font-size: var(--font-size-label-l);
    }
}

@media (--desktop-medium-plus) {
    .responsive.paragraph_xs {
        font-size: var(--font-size-paragraph-s);
        line-height: var(--line-height-paragraph-s);
        letter-spacing: var(--letter-spacing-paragraph-s);
    }

    .responsive.paragraph_s {
        font-size: var(--font-size-paragraph-m);
        line-height: var(--line-height-paragraph-m);
        letter-spacing: var(--letter-spacing-paragraph-m);
    }

    .responsive.paragraph_m {
        font-size: var(--font-size-paragraph-l);
        line-height: var(--line-height-paragraph-l);
        letter-spacing: var(--letter-spacing-paragraph-l);
    }

    .responsive.header_xs {
        font-size: var(--font-size-header-s);
        font-weight: var(--font-weight-header-s);
        line-height: var(--line-height-header);
    }

    .responsive.header_s {
        font-size: var(--font-size-header-m);
        font-weight: var(--font-weight-header-m);
        line-height: var(--line-height-header);
    }

    .responsive.header_m {
        font-size: var(--font-size-header-l);
        font-weight: var(--font-weight-header-l);
        line-height: var(--line-height-header);
    }

    .responsive.header_l {
        font-size: var(--font-size-header-xl);
        font-weight: var(--font-weight-header-xl);
        line-height: var(--line-height-header);
    }

    .responsive.header_xl {
        font-size: var(--font-size-header-xxl);
        font-weight: var(--font-weight-header-xxl);
        line-height: var(--line-height-header);
    }

    .responsive.header_xxl {
        font-size: var(--font-size-header-xxxl);
        font-weight: var(--font-weight-header-xxxl);
        line-height: var(--line-height-header);
    }

    .responsive.label_xs {
        font-size: var(--font-size-label-m);
    }
    .responsive.label_s {
        font-size: var(--font-size-label-l);
    }
}
</style>
