import { createMemoryHistory, createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { ROUTE_NAMES } from '@/router/constants'
import { nonAuthorizedOnlyGuard } from './guards'

export type ImportWrapper = <T>(importCallback: () => T) => () => T
const defaultRegisterImport: ImportWrapper = (x) => () => x()

const routes = (registerImport: ImportWrapper = defaultRegisterImport): RouteRecordRaw[] => [
    {
        path: '/',
        component: registerImport(() => import('@/InitWrapper.vue')),
        children: [
            {
                path: '',
                name: ROUTE_NAMES.MAIN,
                component: registerImport(() => import('@/pages/Homepage/Homepage.vue')),
            },
            {
                path: 'game/:game',
                name: ROUTE_NAMES.GAME_PAGE,
                component: registerImport(() => import('@/pages/Games/GameRouteWrapper.vue')),
            },
            {
                path: 'export/game/:game',
                name: ROUTE_NAMES.EXPORT_GAME_PAGE,
                component: registerImport(() => import('@/pages/Games/ExportGame.vue')),
                meta: {
                    manuallyHandledErrors: true,
                },
            },
            {
                path: 'category/:category',
                name: ROUTE_NAMES.CATEGORY,
                component: registerImport(() => import('@/pages/Category/CategoryPage.vue')),
                beforeEnter: (to) => {
                    const { page, ...queries } = to.query
                    if (page === '1') {
                        return { path: to.path, query: queries, replace: true }
                    }
                    return undefined
                },
            },
            {
                path: 'publishers',
                name: ROUTE_NAMES.PUBLISHERS,
                component: registerImport(() => import('@/pages/old-landings/publishers/Publishers.vue')),
            },
            {
                path: 'advertisers',
                name: ROUTE_NAMES.ADVERTISERS,
                component: registerImport(() => import('@/pages/old-landings/advertisers/Advertisers.vue')),
            },
            {
                path: 'confidential',
                name: ROUTE_NAMES.CONFIDENTIAL,
                component: registerImport(() => import('@/pages/static/StaticPage.vue')),
            },
            {
                path: 'termsofuse',
                name: ROUTE_NAMES.TERMS_OF_USE,
                component: registerImport(() => import('@/pages/static/StaticPage.vue')),
            },
            {
                path: 'takedown-notice',
                name: ROUTE_NAMES.TAKEDOWN_NOTICE,
                component: registerImport(() => import('@/pages/static/StaticPage.vue')),
            },
            {
                path: 'license',
                name: ROUTE_NAMES.LICENSE,
                component: registerImport(() => import('@/pages/static/StaticPage.vue')),
            },
            {
                path: 'partners-api',
                name: ROUTE_NAMES.PARTNERS_API,
                component: registerImport(() => import('@/pages/static/StaticPage.vue')),
            },
            {
                path: 'contacts',
                name: ROUTE_NAMES.CONTACTS,
                component: registerImport(() => import('@/pages/ContactUs/ContactUsPage.vue')),
            },
            {
                path: 'profile',
                name: ROUTE_NAMES.PROFILE,
                component: registerImport(() => import('@/pages/UserProfile.vue')),
            },
            {
                path: 'shop',
                name: ROUTE_NAMES.SHOP,
                component: registerImport(() => import('@/pages/Shop.vue')),
            },
            {
                path: 'invite/:inviteCode',
                name: ROUTE_NAMES.INVITE,
                beforeEnter: nonAuthorizedOnlyGuard,
                component: registerImport(() => import('@/pages/Invite/Invite.vue')),
            },
            {
                path: 'games-categories',
                name: ROUTE_NAMES.GAMES_CATEGORIES,
                component: registerImport(() => import('@/pages/GamesCategories.vue')),
            },
            {
                // this route is for rendering 5xx errors
                // the error itself is rendered by InitWrapper, so here the component is a dummy
                path: '/error',
                component: { template: '' },
            },
            {
                path: '/:catchAll(.*)',
                component: registerImport(() => import('@/pages/Error/NotFoundPage.vue')),
            },
        ],
    },
]

export default function getRouter(isServer: boolean, registerImport?: ImportWrapper) {
    return createRouter({
        routes: routes(registerImport),
        history: isServer ? createMemoryHistory() : createWebHistory(),
        scrollBehavior(to, from, savedPosition) {
            if (to.hash) {
                return {
                    el: to.hash,
                }
            }

            if (savedPosition) {
                return savedPosition
            }

            // special behavior on categories page
            if (to.name === ROUTE_NAMES.CATEGORY) {
                if (from.name === ROUTE_NAMES.CATEGORY) {
                    // do not scroll to top when changing only query params on the same category
                    if (to.params.category === from.params.category) {
                        return false
                    }
                } else if (to.query.page) {
                    // scroll to specific page on direct access
                    return {
                        el: `#page-${to.query.page}`,
                    }
                }
            }

            return { top: 0 }
        },
    })
}
