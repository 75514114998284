import { ref } from 'vue'
import { defineStore } from 'pinia'
import { MainCategoriesType, ShortGameType, CategoryShortInfo } from '@/types'

export type ShortCategory = {
    id: MainCategoriesType
    title: string
    href: string
}
import { getGamesByCategories, getGamesCategoriesShortInfo } from '@/utils/requests/games'
import { shuffle } from '@/utils/helpers'
import { useAppStore } from '@/store/app-store'
import { useRouter } from 'vue-router'
import { ROUTE_NAMES } from '@/router/constants'

const MAX_PROMO_CATEGORIES = 7

export const HIGHLIGHTED_CATEGORY_TYPES: MainCategoriesType[] = ['trending_now', 'new']
const RANDOM_CATEGORY = 'random'
export const TOPIC_CATEGORY_TYPES: MainCategoriesType[] = [
    'io',
    'action',
    'puzzle',
    'arcade',
    'sports',
    'strategy',
    'bubble-shooter',
    'girls',
    'boys',
    'car',
    'simulation',
    'shooting',
    'gun',
    'clicker',
    'funny',
    'snake',
    'skill',
    'drawing',
    'idle',
    'multiplayer',
    'solitaire',
    'horror',
    'two-player',
]

export const useCategoriesStore = defineStore('categories', () => {
    const menuHighlightCategoriesList = ref<ShortCategory[]>([])
    const contentCategoriesList = ref<ShortCategory[]>([])
    const menuCategoriesList = ref<ShortCategory[]>([])
    const promoCategoriesList = ref<ShortCategory[]>([])
    const randomGames = ref<string[]>([])
    const recommendedGames = ref<ShortGameType[] | null>(null)
    const randomGameHRU = ref<string | null>(null)
    const categoriesInfo = ref<CategoryShortInfo[]>([])

    const appStore = useAppStore()
    const router = useRouter()

    function getCategoryRoute(id: string): string {
        try {
            const route = router.resolve({ name: ROUTE_NAMES.CATEGORY, params: { category: id } })
            return route.fullPath
        } catch {
            throw Error(`Could not resolve category - ${id}`)
        }
    }

    async function setMainCategories(): Promise<void> {
        const currentPlatform = appStore.platform
        const mainCategories = [...HIGHLIGHTED_CATEGORY_TYPES, RANDOM_CATEGORY, ...TOPIC_CATEGORY_TYPES]
        // TODO remove pagination on be - now using prevent fail
        const data = await getGamesByCategories({
            platform: currentPlatform,
            pagination: {
                page: 1,
                pageSize: 32,
            },
            categoriesIds: mainCategories, // adding for filtering on be
        })
        const notInternal: ShortCategory[] = []
        const internalCategories: ShortCategory[] = []
        const { categories } = data
        categories.forEach(({ id, title, games }) => {
            if (!games.length) {
                return
            }
            const mainCategoryId = id as MainCategoriesType
            if (HIGHLIGHTED_CATEGORY_TYPES.includes(mainCategoryId)) {
                internalCategories.push({
                    id: mainCategoryId,
                    title,
                    href: getCategoryRoute(id),
                })
            } else if (mainCategoryId === RANDOM_CATEGORY) {
                randomGames.value = games.map((game) => game.hru)
            } else {
                notInternal.push({
                    id: mainCategoryId,
                    title,
                    href: getCategoryRoute(id),
                })
            }
        })

        contentCategoriesList.value = [...internalCategories, ...notInternal]
        menuCategoriesList.value = notInternal
        promoCategoriesList.value = shuffle(notInternal.slice()).slice(
            0,
            Math.min(MAX_PROMO_CATEGORIES, notInternal.length),
        )
        menuHighlightCategoriesList.value = internalCategories
    }
    async function setCategoriesInfo(): Promise<void> {
        if (categoriesInfo.value.length > 0) {
            return
        }
        const result = await getGamesCategoriesShortInfo()
        categoriesInfo.value = result.map((catInfo) => ({ ...catInfo, href: getCategoryRoute(catInfo.id) }))
    }

    function updateRandomGame() {
        const currentIndex = randomGames.value.findIndex((hru) => hru === randomGameHRU.value) || 0
        const nextIndex = currentIndex + 1
        randomGameHRU.value = randomGames.value[nextIndex % randomGames.value.length]
    }

    function shuffleRandomGames(): void {
        randomGames.value = shuffle(randomGames.value)
        updateRandomGame()
    }
    // For Easy shuffling on UI side - as we have cache on ssr we have to reshuffle after page rendered
    async function setMainData() {
        if (contentCategoriesList.value.length) {
            if (typeof window !== 'undefined') {
                shuffleRandomGames()
            }
        } else {
            await setMainCategories()
            if (typeof window !== 'undefined' && contentCategoriesList.value.length) {
                shuffleRandomGames()
            }
        }
    }

    return {
        menuHighlightCategoriesList,
        menuCategoriesList,
        promoCategoriesList,
        contentCategoriesList,
        categoriesInfo,
        recommendedGames,
        setMainData,
        setCategoriesInfo,
        getCategoryRoute,
        // expose to capture on server and hydrate on client
        randomGames,
        randomGameHRU,
        updateRandomGame,
    }
})
