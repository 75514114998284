<template>
    <ModalContainer
        :title="t('title')"
        :description="t('description')"
        :image="InviteImg"
        :alt="t('alt')"
        :isOpened="inviteModalOpened"
        :class="$style.dialog_invite"
        @closeModal="onCloseModal"
    >
        <template #custom-content>
            <div :class="$style.content">
                <div :class="$style.hr" />
                <Typography
                    is="ol"
                    :class="$style.content_description"
                    type="paragraph"
                    size="s"
                    :responsive="false"
                >
                    <li>{{ t('step1') }}</li>
                    <li>{{ t('step2') }}</li>
                    <li>{{ t('step3') }}</li>
                </Typography>
                <div :class="$style.desktop">
                    <TextInput
                        v-if="inviteLinkAbsolute"
                        id="invite-link"
                        name="invite-link"
                        readonly
                        :value="inviteLinkAbsolute"
                    />
                </div>
                <div :class="$style.buttons">
                    <Button
                        width="full"
                        size="l"
                        :disabled="copyBtnDisabled"
                        @click="onCopyLink"
                    >
                        {{ copyBtnLabel }}
                    </Button>
                    <Button
                        width="full"
                        color="carbon-400"
                        size="l"
                        :disabled="copyBtnDisabled"
                        :class="$style.non_desktop"
                        @click="onCloseModal"
                    >
                        {{ t('close') }}
                    </Button>
                </div>
            </div>
        </template>
    </ModalContainer>
</template>
<script setup lang="ts">
import ModalContainer from '@/components_new/ModalItems/ModalContainer.vue'
import InviteImg from '@/components_new/ModalItems/images/invite-friend.webp'
import { useI18n } from 'vue-i18n'
import { useLayoutStore } from '@/store/layout-store'
import { storeToRefs } from 'pinia'
import Button from '@/components_new/Button.vue'
import Typography from '@/components_new/Typography.vue'
import { useUserStore } from '@/store/user-store/user-store'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import TextInput from '@/components_new/TextInput/TextInput.vue'

const { t } = useI18n()
const layoutStore = useLayoutStore()
const userStore = useUserStore()
const router = useRouter()

const { inviteModalOpened } = storeToRefs(layoutStore)

const inviteLinkAbsolute = computed(
    () =>
        (process.env.VUE_APP_HOST_URL || '') +
        router.resolve({ name: 'invite', params: { inviteCode: userStore.user?.referralCode } }).href,
)
const copyBtnDisabled = ref<boolean>(false)
const copyBtnLabel = computed(() => (copyBtnDisabled.value ? t('btn_label_copied') : t('btn_label')))

function onCloseModal() {
    layoutStore.setInviteModalOpened(false)
}

function onCopyLink() {
    if (!inviteLinkAbsolute.value) {
        return
    }
    navigator.clipboard.writeText(inviteLinkAbsolute.value).then(
        () => {
            copyBtnDisabled.value = true
            setTimeout(() => {
                copyBtnDisabled.value = false
            }, 1000 * 2)
        },
        () => {
            console.error('Failed to copy text')
        },
    )
}
</script>
<style module>
.content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
}

.desktop {
    display: none;
}

.buttons {
    margin-top: 8px;
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.hr {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.content_description {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    color: #79797f;
}

@media (--desktop-small-plus) {
    .desktop {
        display: block;
    }

    .non_desktop {
        display: none;
    }

    .buttons {
        margin-top: 0;
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "title": "Invite friends and get rewards",
        "description": "Share your unique link and earn Fennec Coins when friends join and play!",
        "btn_label": "Copy link",
        "btn_label_copied": "Link copied!",
        "alt": "Invite friends",
        "step1": "Share your link with friends",
        "step2": "Friends sign up and play",
        "step3": "You both earn Fennec Coins!",
        "close": "Close"
    }
}
</i18n>
