import { type TaskDto } from '@/types'

const TASK_TYPE_PRIORITY = {
    VISIT_EVERYDAY: 1,
    PLAY_GAME: 2,
    RANDOM_GAME: 3,
    REGISTER: 4,
    REFERRAL_REGISTER: 5,
    REFERRAL_INVITE: 6,
}

const TASK_STATUS_PRIORITY = {
    COMPLETED: 10,
    ACTIVE: 20,
    CLAIMED: 30,
}

export function sortTaskByPriority(tasks: TaskDto[]): TaskDto[] {
    return tasks.sort((a: TaskDto, b: TaskDto) => {
        const priorityA = TASK_TYPE_PRIORITY[a.type] + TASK_STATUS_PRIORITY[a.status] || 1000
        const priorityB = TASK_TYPE_PRIORITY[b.type] + TASK_STATUS_PRIORITY[b.status] || 1000

        return priorityA - priorityB
    })
}
