<template>
    <section v-if="isAuthorized && (showTasks || $slots.default)">
        <div
            v-if="tasksError"
            :class="$style.error_container"
        >
            <Typography
                type="label"
                size="m"
                :class="$style.error_title"
                accent
            >
                {{ t('error_title') }}
            </Typography>
            <Button
                :class="$style.error_button"
                size="l"
                color="carbon-400"
                visual="fill"
                @click="tasksStore.onReloadClick"
            >
                {{ t('error_button') }}
            </Button>
        </div>
        <template v-else>
            <template v-if="!allTasksClaimed">
                <Typography
                    is="h2"
                    type="header"
                    :class="$style.tasks_title"
                    size="xs"
                    :responsive="false"
                >
                    {{ t('title') }}
                </Typography>
                <CardCarousel :items="tasks">
                    <template #default="{ item: task, className }">
                        <TaskCard
                            :task="task"
                            :class="{ [className]: true }"
                            @claimTask="tasksStore.onTaskClaimed"
                            @taskStart="tasksStore.onTaskStart"
                        />
                    </template>
                </CardCarousel>
            </template>
            <slot v-else />
        </template>
    </section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import Typography from '@/components_new/Typography.vue'
import TaskCard from '@/components_new/TaskItems/TaskCard.vue'
import CardCarousel from '@/components_new/CardCarousel.vue'
import Button from '@/components_new/Button.vue'
import { tasksTranslation } from '@/components_new/TaskItems/utils'
import { useTasksStore } from '@/components_new/TaskItems/store'
import { useUserStore } from '@/store/user-store/user-store'

const tasksStore = useTasksStore()
const userStore = useUserStore()
const { tasks, tasksError, showTasks, allTasksClaimed } = storeToRefs(tasksStore)
const { isAuthorized } = storeToRefs(userStore)

const { t } = useI18n({
    messages: {
        en: {
            ...tasksTranslation.en,
            title: 'Daily missions',
        },
    },
})
</script>

<style module>
.error_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 200px;
    border-radius: 12px;
    background-color: var(--carbon-300);
    padding: 10px;
}

.error_button {
    margin-top: 16px;
}

.error_title {
    text-align: center;
}

.tasks_title {
    margin-bottom: 16px;
}
</style>
