import { RewardedBanner } from '@/modules/adv/rewarded-banner'
import { AdProviderStatus, AdvService } from '../adv'

export class EmptyAdsProvider implements AdvService {
    readonly serviceStatus: Promise<AdProviderStatus>

    constructor() {
        this.serviceStatus = Promise.resolve(AdProviderStatus.online)
    }

    // eslint-disable-next-line class-methods-use-this
    prepareOutOfPageAd(): Promise<RewardedBanner> {
        return new Promise((res) => {
            const banner = new RewardedBanner({})

            setTimeout(() => {
                res(banner)
            }, 100)

            setTimeout(() => {
                banner.triggerEmpty()
            }, 200)
        })
    }

    // eslint-disable-next-line class-methods-use-this
    requestPageAd() {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
    }

    // eslint-disable-next-line class-methods-use-this
    updateTargeting() {}
}
