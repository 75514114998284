<template>
    <PromoBanner
        v-if="!isAuthorized && isUserInfoLoaded"
        :image="{ src: FennecCoinsImg, alt: t('sign_in_alt') }"
        :description="t('sign_in_desc')"
        direction="row"
        :title="t('sign_in_title')"
        :buttonPosition="props.buttonPosition"
        textAlign="left"
    >
        <template #default="{ handleClick }">
            <Button
                color="golden"
                size="l"
                @click="handleClick('sign_in')"
            >
                {{ t('sign_in_btn') }}
            </Button>
        </template>
    </PromoBanner>
</template>
<script setup lang="ts">
import Button from '@/components_new/Button.vue'
import FennecCoinsImg from '@/components_new/PromoItems/images/fennek-coin.webp'
import PromoBanner from '@/components_new/PromoItems/PromoBanner.vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'
import { promoItemsTranslation } from '@/components_new/PromoItems/utils'

interface PromoSignInHorizontalProps {
    buttonPosition?: 'right' | 'bottom'
}

const props = withDefaults(defineProps<PromoSignInHorizontalProps>(), {
    buttonPosition: 'right',
})

const { t } = useI18n({
    messages: {
        en: {
            ...promoItemsTranslation.en,
        },
    },
})

const userStore = useUserStore()
const { isAuthorized, isUserInfoLoaded } = storeToRefs(userStore)
</script>
