export const commonNavigationTranslation = {
    en: {
        developers: 'Developers',
        advertisers: 'Advertisers',
        publishers: 'Publishers',
        privacy_policy: 'Privacy Policy',
        contact_us: 'Contact us',
        takedown_notice: 'Takedown notice',
        hiring: 'We are hiring',
    },
}

export const highlightedMenuTranslation = {
    en: {
        shop: 'Shop',
        add_game: 'Add game',
        all_games: 'All games',
        random: 'Random',
    },
}
