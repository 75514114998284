import { shallowRef, type Raw } from 'vue'
import { defineStore } from 'pinia'
import { AdMediator, type AdMediatorOptions } from '@/modules/adv-service'

export const useAdsStore = defineStore('ads', () => {
    const adMediator = shallowRef<Raw<AdMediator> | null>(null)

    function setAdServiceConfig(options: AdMediatorOptions) {
        adMediator.value = new AdMediator(options)
    }

    function updateTargeting(targeting: AdMediatorOptions['targeting']) {
        if (adMediator.value) {
            adMediator.value.updateTargeting(targeting)
        }
    }

    return {
        adMediator,
        setAdServiceConfig,
        updateTargeting,
    }
})
