<template>
    <div :class="$style.container">
        <div :class="$style.left">
            <template v-if="isProfilePage">
                <Typography
                    type="caption_all-caps"
                    :class="$style.caption"
                    :responsive="false"
                >
                    {{ t('gamer') }}
                </Typography>
                <Typography
                    type="label"
                    size="l"
                    accent
                    :class="$style.textBlock"
                    :responsive="false"
                >
                    {{ userName }}
                </Typography>
            </template>
            <template v-else>
                <Typography
                    type="caption_all-caps"
                    :class="$style.caption"
                    :responsive="false"
                >
                    {{ t('my_balance') }}
                </Typography>
                <div :class="$style.textBlock">
                    <Icon name="fennek_coin_small" />
                    <Typography
                        type="header"
                        size="s"
                        :class="$style.balance"
                        :responsive="false"
                    >
                        <Balance :balance="userBalance" />
                    </Typography>
                </div>
            </template>
        </div>
        <div :class="$style.right">
            <UserAvatar
                :class="$style.avatar"
                :avatar="props.user.avatar"
                :firstName="props.user.firstName"
                :lastName="props.user.lastName"
            />
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import UserAvatar from '@/components_new/UserAvatar.vue'
import Typography from '@/components_new/Typography.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Balance from '@/components_new/Balance.vue'
import type { User } from '@/types'

const { t } = useI18n()

const props = defineProps<{
    user: User
    userBalance: number
    isProfilePage: boolean
}>()

const userName = computed(() => `${props.user.firstName || 'Anonimous'} ${props.user.lastName || ''}`)
</script>
<style module>
.container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 12px;
    align-content: center;
}

.left {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.right {
}

.caption {
    color: rgba(255, 255, 255, 0.6);
}

.textBlock {
    display: flex;
    align-items: center;
    height: 24px;
    gap: 6px;
}

.balance {
    flex-grow: 1;
}

.avatar {
    width: 40px;
}
</style>
<i18n lang="json">
{
    "en": {
        "my_balance": "My balance",
        "gamer": "Gamer"
    }
}
</i18n>
