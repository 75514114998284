<template>
    <div :class="$style.footer">
        <div :class="[$style.links_group, $style.links_group_tablet]">
            <Typography
                is="ul"
                type="paragraph"
                size="xs"
                :class="$style.links"
            >
                <li
                    v-for="item in menuHighlightCategoriesList"
                    :key="item.title"
                >
                    <Link
                        :to="item.href"
                        :class="$style.links_item"
                        color="white-60"
                    >
                        {{ item.title }}
                    </Link>
                </li>
                <li>
                    <Link
                        to="/shop"
                        :class="$style.links_item"
                        color="white-60"
                    >
                        {{ t('shop') }}
                    </Link>
                </li>
                <li v-if="randomGamePath">
                    <Link
                        :to="randomGamePath"
                        :class="$style.links_item"
                        color="white-60"
                        @click="categoriesStore.updateRandomGame"
                    >
                        {{ t('random') }}
                    </Link>
                </li>
            </Typography>
        </div>
        <div :class="$style.links_group_tablet">
            <div :class="$style.links_group">
                <Typography
                    is="ul"
                    type="paragraph"
                    size="xs"
                    :class="$style.links"
                >
                    <li
                        v-for="(item, index) in menuCategoriesList"
                        :key="item.title"
                    >
                        <Link
                            :to="item.href"
                            :class="$style.links_item"
                            color="white-60"
                        >
                            {{ item.title }}
                        </Link>
                        <Link
                            v-if="index === menuCategoriesList.length - 1"
                            to="/games-categories"
                            :class="$style.links_item"
                            color="white-60"
                        >
                            {{ t('all_games') }}
                        </Link>
                    </li>
                </Typography>
            </div>
            <div :class="$style.links_group">
                <Typography
                    is="ul"
                    type="paragraph"
                    size="xs"
                    :class="$style.links"
                >
                    <li
                        v-for="item in NAVIGATION_LINKS"
                        :key="item.title"
                    >
                        <Link
                            :to="item.link"
                            :target="item.target"
                            :class="$style.links_item"
                            color="white-60"
                        >
                            {{ t(item.title) }}
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/"
                            :class="$style.links_item"
                            color="white-40"
                        >
                            {{ copyright }}
                        </Link>
                    </li>
                </Typography>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useCategoriesStore } from '@/store/categories-store'
import { storeToRefs } from 'pinia'
import Typography from '@/components_new/Typography.vue'
import Link from '@/components_new/Link.vue'
import { commonNavigationTranslation, highlightedMenuTranslation } from '@/utils/translations/common-navigation'
import { NAVIGATION_LINKS } from '@/constants/general'
import { useRouter } from 'vue-router'
import { ROUTE_NAMES } from '@/router/constants'
import { computed } from 'vue'

const { t } = useI18n({
    messages: {
        en: {
            ...commonNavigationTranslation.en,
            ...highlightedMenuTranslation.en,
            copyright: '© Playgama, {year}',
        },
    },
})

const categoriesStore = useCategoriesStore()
const { menuCategoriesList, menuHighlightCategoriesList, randomGameHRU } = storeToRefs(categoriesStore)

const router = useRouter()
const randomGamePath = computed(
    () =>
        randomGameHRU.value &&
        router.resolve({ name: ROUTE_NAMES.GAME_PAGE, params: { game: randomGameHRU.value } }).fullPath,
)

const copyright = t('copyright', { year: new Date().getFullYear() })
</script>

<style module>
.footer {
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
}

.links_group {
    border-top: 1px solid var(--white-20);
    margin-top: 16px;
    padding-top: 16px;
}

.links {
    columns: 2 auto;
}

.links_item {
    display: block;
    padding: 4px 0;
}

@media (--tablet-plus) {
    .links_group {
        border-top: none;
        margin-top: unset;
        padding-top: unset;
    }

    .links_group_tablet {
        border-top: 1px solid var(--white-20);
        margin-top: 16px;
        padding-top: 16px;
        columns: 5 auto;
    }

    .links_group_tablet:not(:first-of-type) {
        padding-top: 24px;
    }

    .links {
        columns: auto;
        break-after: column;
    }
}
</style>
