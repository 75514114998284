<template>
    <div
        v-if="isAuthorized && (showTasks || $slots.default)"
        :class="$style.task_widgets"
    >
        <template v-if="tasksError">
            <component
                :is="props.redirect ? BaseLink : 'div'"
                to="/profile"
                :class="$style.title"
            >
                <Typography type="caption_all-caps">
                    {{ t('title') }}
                </Typography>
                <Icon
                    v-if="props.redirect"
                    :class="$style.chevron"
                    name="chevron_r"
                />
            </component>
            <span :class="$style.inner_sep" />
            <div :class="$style.error_container">
                <Typography
                    type="label"
                    size="l"
                >
                    {{ t('error_title') }}
                </Typography>
                <Button
                    color="carbon-400"
                    visual="fill"
                    @click="tasksStore.onReloadClick"
                >
                    {{ t('error_button') }}
                </Button>
            </div>
        </template>
        <template v-else>
            <template v-if="!allTasksClaimed">
                <component
                    :is="props.redirect ? BaseLink : 'div'"
                    to="/profile"
                    :class="$style.title"
                >
                    <Typography type="caption_all-caps">
                        {{ t('title') }}
                    </Typography>
                    <Icon
                        v-if="props.redirect"
                        :class="$style.chevron"
                        name="chevron_r"
                    />
                </component>
                <template
                    v-for="task in tasksToShow"
                    :key="task.task_id"
                >
                    <span :class="$style.inner_sep" />
                    <TaskWidget
                        :task="task"
                        :appearance="props.appearance"
                        @claimTask="tasksStore.onTaskClaimed"
                        @taskStart="tasksStore.onTaskStart"
                    />
                </template>
            </template>
            <slot v-else />
        </template>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import Typography from '@/components_new/Typography.vue'
import { computed } from 'vue'
import Icon from '@/components_new/Icon/Icon.vue'
import TaskWidget from '@/components_new/TaskItems/TaskWidget.vue'
import Button from '@/components_new/Button.vue'
import { tasksTranslation } from '@/components_new/TaskItems/utils'
import { useTasksStore } from '@/components_new/TaskItems/store'
import BaseLink from '@/components_new/BaseLink.vue'
import { useUserStore } from '@/store/user-store/user-store'

interface TasksWidgetProps {
    showOnlyFirst?: boolean
    redirect?: boolean
    appearance?: 'default' | 'accent'
}

const props = withDefaults(defineProps<TasksWidgetProps>(), {
    appearance: 'default',
})

const tasksStore = useTasksStore()
const userStore = useUserStore()
const { tasksError, showTasks, allTasksClaimed, notClaimedTasks } = storeToRefs(tasksStore)

const { isAuthorized } = storeToRefs(userStore)
const { t } = useI18n({
    messages: {
        en: {
            ...tasksTranslation.en,
            title: 'Daily Missions',
        },
    },
})

const tasksToShow = computed(() => {
    if (allTasksClaimed.value) {
        return []
    }
    return props.showOnlyFirst ? notClaimedTasks.value.slice(0, 1) : notClaimedTasks.value
})
</script>

<style module>
.task_widgets {
    border-radius: 16px;
    background-color: var(--carbon-300);
    padding: 12px;
}

.title {
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding-bottom: 8px;
    color: rgba(255, 255, 255, 0.6);
}

a.title:hover {
    color: rgba(255, 255, 255, 0.8);
}

.inner_sep {
    height: 1px;
    display: block;
    background-color: rgba(255, 255, 255, 0.08);
    margin-bottom: 12px;
}

.chevron {
    height: 16px;
    width: 16px;
}

.error_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 12px;
    background-color: var(--carbon-300);
    padding: 12px;
    gap: 8px;
}
</style>
