import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { MainCategoriesType, ShortGameZipType } from '@/types'
import { getGamesByCategories } from '@/utils/requests/games'
import { useAppStore } from '@/store/app-store'
import {
    HIGHLIGHTED_CATEGORY_TYPES,
    TOPIC_CATEGORY_TYPES,
    useCategoriesStore,
    ShortCategory,
} from '@/store/categories-store'
import { zipAPIGame } from '@/utils/helpers'

export type MainCategoryListZipType = ShortCategory & {
    games?: ShortGameZipType[]
}

export const useHomepageStore = defineStore('homepage', () => {
    const categoriesWithGames = ref<MainCategoryListZipType[]>([])
    const appStore = useAppStore()
    const categoriesStore = useCategoriesStore()

    async function loadCategoriesWithGames(): Promise<void> {
        if (categoriesWithGames.value.length > 0) {
            return
        }

        const currentPlatform = appStore.platform
        const mainCategories = [...HIGHLIGHTED_CATEGORY_TYPES, ...TOPIC_CATEGORY_TYPES]

        const data = await getGamesByCategories({
            platform: currentPlatform,
            pagination: {
                page: 1,
                pageSize: 32,
            },
            categoriesIds: mainCategories,
        })

        const { categories } = data
        categoriesWithGames.value = categories
            .map(({ id, title, games }) => ({
                id: id as MainCategoriesType,
                title,
                href: categoriesStore.getCategoryRoute(id),
                games: games.map(zipAPIGame),
            }))
            .filter((cat) => cat.games.length > 0)
    }

    return {
        categoriesWithGames,
        loadCategoriesWithGames,
    }
})
