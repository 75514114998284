import { defineStore } from 'pinia'
import { ref } from 'vue'
import { type PlatformType } from '@/types'

export type ErrorStatus = 404 | 500
export type PageStatus = 200 | 301 | 302 | ErrorStatus

const DEFAULT_PLATFORM = 'desktop'
const HOST_URL = process.env.VUE_APP_HOST_URL as string

export const useAppStore = defineStore('app', () => {
    const pageStatus = ref<PageStatus>(200)
    const targetURL = ref('')
    const clid = ref('')
    const widgetId = ref<string | undefined>(undefined)
    const platform = ref<PlatformType>(DEFAULT_PLATFORM)

    const isAuthBannerOpen = ref<boolean>(false)

    function setPageStatus(status: PageStatus) {
        pageStatus.value = status
    }

    function setTargetURL(target: string, origin: string = HOST_URL) {
        targetURL.value = `${origin}${target}`
    }

    function setPlatform(plt: PlatformType) {
        platform.value = plt
    }

    function setEnv(env: { clid: string; widgetId?: string }) {
        clid.value = env.clid
        widgetId.value = env.widgetId
    }

    function $reset() {
        pageStatus.value = 200
        targetURL.value = ''
        platform.value = DEFAULT_PLATFORM
    }

    return {
        setPageStatus,
        setTargetURL,
        setEnv,
        pageStatus,
        targetURL,
        clid,
        widgetId,
        platform,
        isAuthBannerOpen,
        setPlatform,
        $reset,
    }
})
