import { createPinia as originalCreatePinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const createPinia = () => {
    const pinia = originalCreatePinia()
    // in ssr catch error if plugin doesn't get window object
    if (typeof window !== 'undefined') {
        pinia.use(piniaPluginPersistedstate)
    }
    return pinia
}

export default createPinia
