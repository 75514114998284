<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
    <header :class="$style.header">
        <div :class="$style.scroller">
            <template
                v-for="item in menuCategoriesList"
                :key="item.id"
            >
                <Tooltip
                    :content="item.title"
                    :class="$style.item"
                >
                    <!-- otherwise hidden (out of viewport) rows will be focusable -->
                    <BaseLink
                        tabindex="-1"
                        :to="item.href"
                        :class="$style.item_link"
                        @click="goToCategory"
                    >
                        <Icon
                            :name="item.id"
                            :alt="item.title"
                            :class="$style.item_icon"
                        />
                    </BaseLink>
                </Tooltip>
            </template>
        </div>
        <Button
            color="carbon-300"
            @click="setCategoriesModalOpened(true)"
        >
            {{ t('openCategoriesBtnTitle') }}
        </Button>
    </header>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useCategoriesStore } from '@/store/categories-store'
import { useLayoutStore } from '@/store/layout-store'
import { useLoggerStore } from '@/store/logger-store'
import Icon from '@/components_new/Icon/Icon.vue'
import Tooltip from '@/components_new/Tooltip.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import Button from './Button.vue'

const loggerStore = useLoggerStore()

const { setCategoriesModalOpened } = useLayoutStore()

const { t } = useI18n({
    messages: {
        en: {
            openCategoriesBtnTitle: 'All games',
        },
    },
})

const categoriesStore = useCategoriesStore()

const { menuCategoriesList } = storeToRefs(categoriesStore)

function goToCategory() {
    loggerStore.logEvent({
        event: 'custom_event',
        eventName: 'navigate',
        label: 'categories_header',
        action: 'click',
    })
}
</script>
<style module>
.header {
    --min-item-width: 38px;
    --item-height: 24px;
    --hover-shift: 10px;
    --icon-additional-spacing: calc(var(--hover-shift) + 2px);
    overflow: hidden;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: calc(var(--icon-additional-spacing) * -1);
}

.scroller {
    height: 100%;
    flex-grow: 1;
    padding-left: var(--icon-additional-spacing);
    padding-right: var(--icon-additional-spacing);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--min-item-width), 1fr));
    grid-template-rows: var(--item-height);
    grid-auto-rows: 0;
    /* just to get the other rows out of view */
    row-gap: 200px;
    width: 100%;
    transform: translate(0, calc(50% - var(--item-height) / 2));
}

.item {
    color: var(--purple-500);
    display: flex;
    justify-content: center;
    will-change: transform;
    transition: transform 0.15s ease-out;
    position: relative;
}

.item:before {
    content: '';
    position: absolute;
    inset: calc(var(--hover-shift) * -0.5);
    pointer-events: none;
}

.item_icon {
    will-change: transform;
    transition: transform 0.15s ease-out;
    /* make icon twice bigger for better quiality in focused state */
    width: 48px;
    height: 48px;
    transform: scale(0.5);
    /* shrink layout size back to 24px */
    margin: -12px;
}

.item_link {
    outline: none;
    width: 100%;
    text-align: center;
}

.item:hover,
.item:has(:focus-visible),
.item:focus-within {
    color: var(--electric-lime);
    outline: none;
}

.item:hover .item_icon,
.item:has(:focus-visible) .item_icon {
    transform: scale(1);
}

.item:has(~ .item:hover),
.item:has(~ .item:has(:focus-visible)) {
    transform: translate(calc(var(--hover-shift) * -1), 0);
}

.item:has(+ .item:hover) .item_icon,
.item:has(+ .item:has(:focus-visible)) .item_icon {
    transform: scale(0.75);
}

.item:hover ~ .item,
.item:has(:focus-visible) ~ .item {
    transform: translate(var(--hover-shift), 0);
}

.item:hover + .item .item_icon,
.item:has(:focus-visible) + .item .item_icon {
    transform: scale(0.75);
}
</style>
