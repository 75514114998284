export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Online Games Now on Playgama 🎮 Play NOW!"])},
        "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Games Online"])},
        "alt_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Games Online"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dive into the best online games at Playgama! 🎮 Play, earn, and score real-world rewards — no joke! Start your adventure now on desktop, mobile, or even that tablet you forgot you had!🕹️"])}
      }
    }
  })
}
