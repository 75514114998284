import { APIShortGameType, ShortGameType, ShortGameZipType, type PlatformType } from '@/types'

export function getCurrentPlatform(userAgent: string): PlatformType {
    if (userAgent.match(/(iPhone|iPad|iPod)/)) {
        return 'ios'
    }
    if (userAgent.match(/Android/)) {
        return 'android'
    }
    return 'desktop'
}

export function getRandomNumber(to: number, from = 0, radix = 10): string {
    return (from + Math.round(Math.random() * (to - from))).toString(radix)
}

export function shuffle<T>(array: T[]): T[] {
    const resultArray = array
    let currentIndex = array.length
    let temporaryValue
    let randomIndex
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1
        temporaryValue = array[currentIndex]
        resultArray[currentIndex] = array[randomIndex]
        resultArray[randomIndex] = temporaryValue
    }

    return resultArray
}

export function timer(timeout: number): Promise<void> {
    return new Promise((res) => {
        setTimeout(() => {
            res()
        }, timeout)
    })
}

export function zipAPIGame(game: APIShortGameType): ShortGameZipType {
    return [game.hru, game.title, game.preview, game.videos?.[0]?.preview_url]
}

export function unzipShortGame([hru, title, preview, video]: ShortGameZipType): ShortGameType {
    return { hru, title, preview, video }
}
