import { RewardedBanner } from '@/modules/adv/rewarded-banner'
import { Banner, BannerState } from '@/modules/adv/banner'
import { timer } from '@/utils/helpers'
import {
    AdProviderStatus,
    AdvService,
    type AdvServiceConfig,
    type OutOfPageAdType,
    type PageAdOptions,
    type PageAdType,
} from '../adv'

export const GAM_SOURCE = 'https://www.googletagservices.com/tag/js/gpt.js'
export type AdUnitName =
    | 'h5_interstitial'
    | 'h5_interstitial_preroll'
    | 'rewarded'
    | 'interstitial'
    | 'anchor_mobile'
    | 'sidebar'
    | 'leaderboard'
    | 'leaderboard_rest'
    | 'catalog'
    | 'catalog_mobile'
    | 'catalog_mobile_rest'

const TypeToUnitMap: Record<OutOfPageAdType | PageAdType, AdUnitName> = {
    rewarded: 'rewarded',
    interstitial: 'h5_interstitial',
    interstitial_preroll: 'h5_interstitial_preroll',
    fullscreen: 'interstitial',
    sticky_portrait: 'anchor_mobile',
    sidebar: 'sidebar',
    leaderboard: 'leaderboard',
    leaderboard_rest: 'leaderboard_rest',
    catalog: 'catalog',
    catalog_mobile: 'catalog_mobile',
    catalog_mobile_rest: 'catalog_mobile_rest',
}
const ADMANAGER_ACCOUNT = process.env.VUE_APP_ADMANAGER_ACCOUNT
const GAME_INTERSTITIAL_TIMEOUT = 120_000
const CONSENT_LOCALSTORAGE_KEY = 'cookieyes-consent'
const CONSENT_UPDATE_INTERVAL = 10_000
export class GoogleAdsService implements AdvService {
    private readonly gpt: typeof googletag

    private readonly slots = new Map<googletag.Slot, Banner | RewardedBanner>()

    private readonly preloadedGameBannerCache = new Map<OutOfPageAdType, Promise<RewardedBanner>>()

    private formats: Record<OutOfPageAdType, googletag.enums.OutOfPageFormat> | null = null

    private gameManualInterstitialTriggeredAt = 0

    readonly serviceStatus: Promise<AdProviderStatus>

    constructor({ targeting = {}, preloadGameAd }: AdvServiceConfig) {
        window.googletag = window.googletag || { cmd: [] }
        window.googletag.cmd = window.googletag.cmd || [] // it may be not empty after reload with cached resources

        this.gpt = window.googletag

        this.serviceStatus = new Promise((res, rej) => {
            const script = document.createElement('script')
            script.src = GAM_SOURCE

            script.addEventListener('error', () => {
                rej(AdProviderStatus.offline)
            })
            document.body.appendChild(script)

            this.gpt.cmd.push(() => {
                this.retrievePrivacySettings()

                this.formats = {
                    // @ts-ignore Google doesn't care about updating their types library
                    interstitial: this.gpt.enums.OutOfPageFormat.GAME_MANUAL_INTERSTITIAL,
                    // @ts-ignore Google doesn't care about updating their types library
                    interstitial_preroll: this.gpt.enums.OutOfPageFormat.GAME_MANUAL_INTERSTITIAL,
                    // @ts-ignore Google doesn't care about updating their types library
                    fullscreen: this.gpt.enums.OutOfPageFormat.GAME_MANUAL_INTERSTITIAL,
                    rewarded: this.gpt.enums.OutOfPageFormat.REWARDED,
                    sticky_portrait: this.gpt.enums.OutOfPageFormat.BOTTOM_ANCHOR,
                }

                Object.entries(targeting).forEach(([key, value]) => {
                    this.gpt.pubads().setTargeting(key, value)
                })

                this.gpt.pubads().set('page_url', 'playgama.com')

                this.gpt.pubads().addEventListener('rewardedSlotReady', (event) => {
                    const banner = this.slots.get(event.slot) as RewardedBanner
                    if (!banner) {
                        return
                    }
                    banner.triggerReady(event.makeRewardedVisible)
                })
                // @ts-ignore Google doesn't care about updating their types library
                this.gpt.pubads().addEventListener('gameManualInterstitialSlotReady', (event) => {
                    // @ts-ignore Google doesn't care about updating their types library
                    this.slots.get(event.slot)?.triggerReady(event.makeGameManualInterstitialVisible)
                    this.gameManualInterstitialTriggeredAt = Date.now()
                })

                this.gpt.pubads().addEventListener('rewardedSlotClosed', (event) => {
                    const banner = this.slots.get(event.slot)
                    if (banner) {
                        banner.triggerClosed()
                    }
                })

                // @ts-ignore Google doesn't care about updating their types library
                this.gpt.pubads().addEventListener('gameManualInterstitialSlotClosed', (event) => {
                    const banner = this.slots.get(event.slot)
                    if (banner) {
                        banner.triggerClosed()
                    }
                })

                this.gpt.pubads().addEventListener('rewardedSlotGranted', (event) => {
                    this.slots.get(event.slot)?.triggerRewarded(event.payload)
                })

                this.gpt.pubads().addEventListener('impressionViewable', (event) => {
                    this.slots.get(event.slot)?.triggerViewable()
                })

                this.gpt.pubads().addEventListener('slotRenderEnded', (event) => {
                    if (event.isEmpty) {
                        this.slots.get(event.slot)?.triggerEmpty()
                    } else {
                        this.slots.get(event.slot)?.triggerRendered()
                    }
                })
                if (preloadGameAd) {
                    this.preloadAd('interstitial_preroll')
                    this.preloadAd('rewarded')
                }

                res(AdProviderStatus.online)
            })
        })
    }
    // eslint-disable-next-line class-methods-use-this
    private getLocalPrivacySettings(): CookieYesConsentData | null {
        let parsedLocalConsent = null
        try {
            const localConsent = window.localStorage.getItem(CONSENT_LOCALSTORAGE_KEY)
            if (localConsent) {
                parsedLocalConsent = JSON.parse(localConsent)
            }
        } catch {
            // it's ok
        }
        return parsedLocalConsent
    }

    private retrievePrivacySettings() {
        try {
            // regular page
            if (window.getCkyConsent) {
                const consent = window.getCkyConsent()
                try {
                    window.localStorage.setItem(CONSENT_LOCALSTORAGE_KEY, JSON.stringify(consent))
                } catch {
                    // can't access localStorage
                }
                this.setPrivacySettings(consent)
            } else {
                // export page
                const parsedLocalConsent = this.getLocalPrivacySettings()
                this.setPrivacySettings(parsedLocalConsent)
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('consent retrieving error', error)
        }
    }

    private setPrivacySettings(consent: CookieYesConsentData | null) {
        let nonPersonalizedAds = true
        try {
            if (consent) {
                if (!consent.activeLaw) {
                    nonPersonalizedAds = false
                } else if (consent.isUserActionCompleted && consent.categories.advertisement) {
                    nonPersonalizedAds = false
                }
            }
        } catch {
            // parsedLocalContent might be corrupted
        }

        this.gpt.pubads().setPrivacySettings({
            nonPersonalizedAds,
        })

        if (nonPersonalizedAds) {
            this.gpt.setConfig({ privacyTreatments: { treatments: ['disablePersonalization'] } })
        } else {
            this.gpt.setConfig({ privacyTreatments: { treatments: [] } })
        }

        if (!consent || (consent.activeLaw && !consent.isUserActionCompleted)) {
            setTimeout(() => {
                this.gpt.cmd.push(() => this.retrievePrivacySettings())
            }, CONSENT_UPDATE_INTERVAL)
        }
    }

    private preloadAd(type: OutOfPageAdType): Promise<RewardedBanner> {
        const unit = TypeToUnitMap[type]
        let typeToLoad = type
        if (
            unit === 'h5_interstitial' &&
            Date.now() < this.gameManualInterstitialTriggeredAt + GAME_INTERSTITIAL_TIMEOUT
        ) {
            // preload rewarded instead of interstitial
            typeToLoad = 'rewarded'
        }

        const bannerPromise = new Promise<RewardedBanner>((res, rej) => {
            let slot: googletag.Slot | null
            this.gpt.cmd.push(() => {
                slot = this.gpt.defineOutOfPageSlot(`${ADMANAGER_ACCOUNT}/${unit}`, this.formats![typeToLoad])

                // Slot returns null if the page or device does not support rewarded ads.
                if (!slot) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    rej()
                    return
                }

                const banner = new RewardedBanner({
                    destroy: () => {
                        this.slots.delete(slot!)
                        this.gpt.cmd.push(() => {
                            googletag.destroySlots([slot!])
                            if (unit === 'rewarded') {
                                // prelaod for rewarded banners only,
                                // because game interstitial banners have a timeout for a trigger event.
                                // taking into account timeout, it's better not to preload them
                                // to don't make the code messy
                                this.gpt.cmd.push(() => {
                                    this.preloadAd(typeToLoad)
                                })
                            }
                        })
                    },
                })

                this.slots.set(slot, banner)

                res(banner)

                slot.addService(this.gpt.pubads())

                this.gpt.enableServices()
                this.gpt.display(slot)

                banner.addEventListener('closed', () => {
                    banner.destroy()
                })
            })
        })
        this.preloadedGameBannerCache.set(type, bannerPromise)
        return bannerPromise
    }

    // https://developers.google.com/publisher-tag/samples/display-rewarded-ad

    async prepareOutOfPageAd(type: OutOfPageAdType, timeout?: number): Promise<RewardedBanner> {
        // eslint-disable-next-line prefer-promise-reject-errors
        const rejectByTimeout = timeout ? timer(timeout) : Promise.reject()
        const bannerPromise = this.preloadedGameBannerCache.get(type)

        this.preloadedGameBannerCache.delete(type)

        const loadingBanner = await (bannerPromise || this.preloadAd(type)) // an unprocessable slot triggers rejection
        this.preloadedGameBannerCache.delete(type)
        if (!loadingBanner) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject()
        }
        rejectByTimeout.then(() => {
            if (loadingBanner.state === BannerState.loading) {
                console.info('empty by timeout, google')
                loadingBanner.triggerEmpty()
                loadingBanner.destroy()
            }
        })
        // duplicate banner state event
        switch (loadingBanner.state) {
            case BannerState.ready:
                setTimeout(() => {
                    loadingBanner.triggerReady()
                }, 16)
                break
            case BannerState.empty:
                setTimeout(() => {
                    loadingBanner.triggerEmpty()
                    loadingBanner.destroy()
                }, 16)
                break
            default:
                break
        }

        return loadingBanner
    }

    requestPageAd(options: PageAdOptions): Promise<Banner> {
        const unit = TypeToUnitMap[options.type]
        return new Promise((resolve, reject) => {
            this.gpt.cmd.push(() => {
                let refreshInterval: ReturnType<typeof setInterval>
                let slot: googletag.Slot | null = null
                if (options.type === 'sticky_portrait') {
                    slot = this.gpt.defineOutOfPageSlot(`${ADMANAGER_ACCOUNT}/${unit}`, this.formats!.sticky_portrait)
                } else if (options.sizes) {
                    slot = this.gpt.defineSlot(`${ADMANAGER_ACCOUNT}/${unit}`, options.sizes, options.el)
                }
                if (!slot) {
                    console.error('Failed to define slot')
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject()
                    return
                }

                slot.addService(this.gpt.pubads())
                this.gpt.enableServices()
                this.gpt.pubads().setCentering(true)
                this.gpt.pubads().collapseEmptyDivs()
                this.gpt.display(slot)

                if (options.refresh) {
                    refreshInterval = setInterval(() => {
                        this.gpt.pubads().refresh([slot])
                    }, options.refresh * 1000)
                }

                const banner = new Banner({
                    destroy: () => {
                        this.gpt.destroySlots([slot])
                        if (refreshInterval) {
                            clearInterval(refreshInterval)
                        }
                        this.slots.delete(slot)
                    },
                })

                this.slots.set(slot, banner)

                resolve(banner)
            })
        })
    }

    updateTargeting(targeting: AdvServiceConfig['targeting'] = {}) {
        this.gpt.cmd.push(() => {
            Object.entries(targeting).forEach(([key, value]) => {
                if (value) {
                    this.gpt.pubads().setTargeting(key, value)
                } else {
                    this.gpt.pubads().clearTargeting(key)
                }
            })
        })
    }
}
