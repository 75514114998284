import type { RouteLocationNormalizedLoaded } from 'vue-router'
import { type EventForLoggersType, type GameEvent, GameProviders } from '@/types'
import { RewardedBanner } from '@/modules/adv/rewarded-banner'
import { DebugAdsProvider } from '@/modules/debug-provider'
import { GameDistributionConnector } from '../gd-connector/gd-connector'
import { GoogleAdsService } from '../google-ads'
import { YandexAdvertisingNetwork } from '../yandex-advertising-network'
import { EmptyAdsProvider } from '../empty-ads'
import {
    AdProviderStatus,
    AdvAction,
    AdvService as AdvProvider,
    type AdvServiceConfig,
    type ManualAdOptions,
    type OutOfPageAdType,
    type PageAdOptions,
} from '../adv'
import EventBus from '../event-bus'
import { type AdProviderType, config } from './config'

const { VUE_APP_AD_REQUEST_TIMEOUT_SEC = '5' } = process.env
const AD_REQUEST_TIMEOUT = Number.parseInt(VUE_APP_AD_REQUEST_TIMEOUT_SEC, 10) * 1000

export type AdMediatorOptions = {
    configKey: keyof typeof config
    targeting?: Record<string, string | string[]>
    logEvent: (event: EventForLoggersType) => void
    route?: RouteLocationNormalizedLoaded
    preloadGameAd: boolean
}

const INNER_MESSAGE_ID = 'inner_call'
const FIRST_INTERSTITIAL_TIMEOUT = 30_000
const OUT_OF_PAGE_TYPES = ['fullscreen', 'interstitial', 'interstitial_preroll', 'rewarded']

type AdStatus = 'start' | 'error' | 'empty' | 'done' | 'open' | 'show' | 'rewarded' | 'close'

type AdResponsePayload = {
    status: AdStatus
    error?: string
}

const TYPE_SETTINGS: Record<AdvAction, OutOfPageAdType> = {
    [AdvAction.preloadRewarded]: 'rewarded',
    [AdvAction.showRewarded]: 'rewarded',
    [AdvAction.preloadInterstitial]: 'interstitial',
    [AdvAction.showInterstitial]: 'interstitial',
}

function getProvider(adProvider: GameProviders) {
    switch (adProvider) {
        case GameProviders.YANDEX_AD:
            return YandexAdvertisingNetwork
        case GameProviders.GAME_DISTRIBUTION:
            return GameDistributionConnector
        case GameProviders.DEBUG_PROVIDER:
            return DebugAdsProvider
        case GameProviders.EMPTY_PROVIDER:
            return EmptyAdsProvider
        case GameProviders.GOOGLE_AD:
        default:
            return GoogleAdsService
    }
}

type ShortGameEvent = {
    data: {
        id: string
        action: AdvAction
        payload?: Record<string, unknown>
        responseToId?: string
        type: 'adv' | 'liveness' | 'error'
    }
}

export class AdMediator {
    private isShowingFullscreenAd = false

    private isInterstitialBlocked = false

    private config: AdProviderType

    private banners: Map<OutOfPageAdType, RewardedBanner>

    private adProvider: AdvProvider

    private adFallback: AdvProvider | undefined

    private originalMessages: Map<string, ShortGameEvent>

    private readonly serviceReadyPromise: Promise<AdProviderStatus>

    private logEvent: (event: EventForLoggersType) => void

    private route?: RouteLocationNormalizedLoaded

    eventBus: EventBus

    constructor(options: AdMediatorOptions) {
        this.config = config[options.configKey]
        const Provider = getProvider(this.config.adProvider)
        this.adProvider = new Provider({ targeting: options.targeting, preloadGameAd: options.preloadGameAd })
        this.logEvent = options.logEvent
        this.route = options.route

        if (this.config.fallback) {
            const Fallback = getProvider(this.config.fallback)
            this.adFallback = new Fallback({ targeting: options.targeting, preloadGameAd: false })
        }
        this.banners = new Map()
        this.eventBus = new EventBus()
        this.originalMessages = new Map()
        this.serviceReadyPromise = new Promise((res, rej) => {
            this.adProvider.serviceStatus.then(
                () => {
                    res(AdProviderStatus.online)
                },
                () => {
                    if (this.adFallback) {
                        this.adProvider = this.adFallback
                        this.adFallback = undefined
                        this.adProvider.serviceStatus.then(
                            () => {
                                res(AdProviderStatus.online)
                            },
                            () => {
                                rej(AdProviderStatus.offline)
                            },
                        )
                    } else {
                        rej(AdProviderStatus.offline)
                    }
                },
            )
        })
    }

    handleMessage(event: GameEvent) {
        this.originalMessages.set(event.data.id, event)

        switch (event.data.action) {
            case 'preloadInterstitial':
                this.preloadAd('interstitial', event.data.id)
                break
            case 'preloadRewarded':
                this.preloadAd('rewarded', event.data.id)
                break
            case 'showInterstitial':
                if (this.isInterstitialBlocked) {
                    this.handleBlockedInterstitial(event.data.id)
                } else {
                    this.showAd('interstitial', event.data.id)
                }
                break
            case 'showRewarded':
                this.showAd('rewarded', event.data.id)
                break
            default:
                this.eventBus.dispatch('adMessage', {
                    originalMessageEvent: event as GameEvent,
                    payload: {
                        error: `Unknown message action "${event.data.action}"`,
                    },
                    type: 'error',
                })
                this.originalMessages.delete(event.data.id)
        }
    }

    showPreroll() {
        this.originalMessages.set(INNER_MESSAGE_ID, {
            data: {
                id: INNER_MESSAGE_ID,
                action: AdvAction.showInterstitial,
                type: 'adv',
            },
        })
        this.isInterstitialBlocked = true
        this.showAd('interstitial_preroll', INNER_MESSAGE_ID)
        setTimeout(() => {
            this.isInterstitialBlocked = false
        }, FIRST_INTERSTITIAL_TIMEOUT)
    }

    handleBlockedInterstitial(id: string) {
        this.triggerMessage({ status: 'start' }, id)
        setTimeout(() => {
            this.triggerMessage({ status: 'done' }, id)
        }, 300)
        setTimeout(() => {
            this.triggerMessage({ status: 'show' }, id)
        }, 1_000)
        setTimeout(() => {
            this.triggerMessage({ status: 'close' }, id)
            this.originalMessages.delete(id)
        }, 3_000)
    }

    async prepareAd(options: ManualAdOptions) {
        const adEvent: Omit<EventForLoggersType, 'action'> = {
            event: 'custom_event',
            eventName: 'ad_request',
            label: options.type,
            pageName: (this.route?.name as string) || undefined,
        }

        this.logEvent({
            ...adEvent,
            action: 'start',
        })

        try {
            await this.serviceReadyPromise
        } catch {
            this.logEvent({
                ...adEvent,
                action: 'error',
            })
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject()
        }

        try {
            const banner =
                OUT_OF_PAGE_TYPES.indexOf(options.type) > -1
                    ? await this.adProvider.prepareOutOfPageAd(options.type as OutOfPageAdType)
                    : await this.adProvider.requestPageAd(options as PageAdOptions)
            banner.addEventListener('ready', () => {
                if (banner instanceof RewardedBanner && banner.show) {
                    banner.show()
                }
            })

            banner.addEventListener('empty', () =>
                this.logEvent({
                    ...adEvent,
                    action: 'empty',
                }),
            )

            banner.addEventListener('viewable', () => {
                this.logEvent({
                    ...adEvent,
                    action: 'show',
                })
                this.logEvent({
                    event: 'custom_event',
                    eventName: 'ad_show',
                    label: options.type,
                    action: 'show',
                    pageName: (this.route?.name as string) || undefined,
                })
            })

            return banner
        } catch {
            this.logEvent({
                ...adEvent,
                action: 'error',
            })
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject()
        }
    }

    private async showAd(type: OutOfPageAdType, messageId: string) {
        if (this.isShowingFullscreenAd) {
            this.triggerMessage(
                {
                    status: 'error',
                    error: 'Another ad is already open',
                },
                messageId,
            )
            return
        }

        let banner = this.banners.get(type)
        if (!banner) {
            try {
                banner = await this.preloadAd(type, messageId)
            } catch {
                // Messages have been sent from the method
                this.isShowingFullscreenAd = false
                return
            }
        }
        this.listenToRenderedBannerEvents(banner, messageId)

        this.isShowingFullscreenAd = true

        banner.addEventListener('empty', () => {
            // sometimes we don't know a banner is empty till show it.
            // we can't move this code to the general empty banner handler
            // because an ad may be loading while showing other ad
            this.isShowingFullscreenAd = false
            this.triggerMessage({ status: 'close' }, messageId)
        })

        // the banner is in the ready status
        banner.show()

        // we don't have to keep it anymore, listeners are setted
        this.banners.delete(type)
    }

    /**
     * @return Promise<RewardedBanner> - a ready-to-show banner.
     * If there was an error or slot was empty - rejection will be returned
     */
    private async preloadAd(type: OutOfPageAdType, messageId: string): Promise<RewardedBanner> {
        try {
            await this.serviceReadyPromise
        } catch {
            // сегодня без рекламы
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject()
        }
        const message = this.originalMessages.get(messageId)
        if (!message) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject()
        }
        let banner: RewardedBanner
        this.triggerMessage({ status: 'start' }, messageId)
        this.logEvent({
            event: 'custom_event',
            eventName: 'ad_request',
            label: type,
            action: 'start',
            pageName: (this.route?.name as string) || undefined,
        })
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                banner = await this.adProvider.prepareOutOfPageAd(type, AD_REQUEST_TIMEOUT)
            } catch {
                if (!this.adFallback) {
                    this.triggerMessage({ status: 'error', error: 'Cannot show the ad' }, messageId)
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject()
                    this.originalMessages.delete(messageId)
                    return
                }
                try {
                    banner = await this.adFallback.prepareOutOfPageAd(type, AD_REQUEST_TIMEOUT)
                } catch {
                    this.triggerMessage({ status: 'error', error: 'Cannot show the ad' }, messageId)
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject()
                    this.originalMessages.delete(messageId)
                    return
                }
            }

            banner.addEventListener('empty', async () => {
                if (this.adFallback) {
                    let fallback: RewardedBanner
                    try {
                        fallback = await this.adFallback.prepareOutOfPageAd(type, AD_REQUEST_TIMEOUT)
                    } catch {
                        // errors in fallback shouldn't change empty status to error
                        this.triggerMessage({ status: 'empty' }, messageId)
                        // eslint-disable-next-line prefer-promise-reject-errors
                        reject()
                        this.originalMessages.delete(messageId)
                        this.isShowingFullscreenAd = false
                        this.logEvent({
                            event: 'custom_event',
                            eventName: 'ad_request',
                            label: type,
                            action: 'empty',
                            pageName: (this.route?.name as string) || undefined,
                        })
                        return
                    }
                    fallback.addEventListener('empty', () => {
                        this.triggerMessage({ status: 'empty' }, messageId)
                        // eslint-disable-next-line prefer-promise-reject-errors
                        reject()
                        this.originalMessages.delete(messageId)
                        this.isShowingFullscreenAd = false
                        this.logEvent({
                            event: 'custom_event',
                            eventName: 'ad_request',
                            label: type,
                            action: 'empty',
                            pageName: (this.route?.name as string) || undefined,
                        })
                    })

                    fallback.addEventListener('ready', () => {
                        this.triggerMessage({ status: 'done' }, messageId)
                        this.banners.set(type, fallback)
                        resolve(fallback)
                    })
                } else {
                    this.triggerMessage({ status: 'empty' }, messageId)
                    this.logEvent({
                        event: 'custom_event',
                        eventName: 'ad_request',
                        label: type,
                        action: 'empty',
                        pageName: (this.route?.name as string) || undefined,
                    })
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject()
                }
            })

            banner.addEventListener('ready', () => {
                this.triggerMessage({ status: 'done' }, messageId)
                this.banners.set(type, banner)
                resolve(banner)
            })
        })
    }

    private listenToRenderedBannerEvents(banner: RewardedBanner, messageId: string) {
        const message = this.originalMessages.get(messageId)
        if (!message) {
            return
        }
        const { action } = message.data
        const type = TYPE_SETTINGS[action]

        if (type === 'rewarded') {
            banner.addEventListener('rewarded', () => {
                this.logEvent({
                    event: 'custom_event',
                    eventName: 'ad_request',
                    label: type,
                    action: 'rewarded',
                    pageName: (this.route?.name as string) || undefined,
                })

                this.triggerMessage({ status: 'rewarded' }, messageId)
            })
        }

        banner.addEventListener('closed', () => {
            this.triggerMessage({ status: 'close' }, messageId)
            this.isShowingFullscreenAd = false
            this.originalMessages.delete(messageId)
            this.logEvent({
                event: 'custom_event',
                eventName: 'ad_request',
                label: type,
                action: 'close',
                pageName: (this.route?.name as string) || undefined,
            })
        })

        banner.addEventListener('rendered', () => {
            this.triggerMessage({ status: 'open' }, messageId)
        })

        banner.addEventListener('viewable', () => {
            this.logEvent({
                event: 'custom_event',
                eventName: 'ad_request',
                label: type,
                action: 'show',
                pageName: (this.route?.name as string) || undefined,
            })

            this.logEvent({
                event: 'custom_event',
                eventName: 'ad_show',
                label: type,
                action: 'show',
                pageName: (this.route?.name as string) || undefined,
            })

            this.triggerMessage({ status: 'show' }, messageId)
        })
    }

    private triggerMessage(payload: AdResponsePayload, messageId: string) {
        if (payload.status === 'close') {
            this.eventBus.dispatch('adClose', {})
        }

        const message = this.originalMessages.get(messageId)
        if (!message || messageId === INNER_MESSAGE_ID) {
            return
        }
        const { action } = message.data
        this.eventBus.dispatch('adMessage', {
            action,
            type: 'adv',
            originalMessageEvent: message,
            payload,
        })
    }

    updateTargeting(targeting: AdvServiceConfig['targeting']) {
        this.adProvider.updateTargeting(targeting)
        if (this.adFallback) {
            this.adFallback.updateTargeting(targeting)
        }
    }
}
