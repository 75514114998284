export const ROUTE_NAMES = {
    MAIN: 'main',
    GAME_PAGE: 'game-page',
    EXPORT_GAME_PAGE: 'export-game-page',
    CATEGORY: 'category',
    PUBLISHERS: 'publishers',
    ADVERTISERS: 'advertisers',
    CONFIDENTIAL: 'confidential',
    TERMS_OF_USE: 'termsofuse',
    LICENSE: 'license',
    PARTNERS_API: 'partners-api',
    CONTACTS: 'contacts',
    PROFILE: 'profile',
    SHOP: 'shop',
    INVITE: 'invite',
    GAMES_CATEGORIES: 'games-categories',
    TAKEDOWN_NOTICE: 'takedown-notice',
} as const
