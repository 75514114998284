<template>
    <div :class="$style.container">
        <div :class="$style.center">
            <img
                :class="$style.img"
                :src="content.img"
                :alt="content.imgAlt"
            />
            <div :class="$style.texts">
                <Typography
                    type="header"
                    size="m"
                    as="h2"
                >
                    {{ content.title }}
                </Typography>
                <Typography
                    type="paragraph"
                    size="s"
                >
                    {{ content.text }}
                </Typography>
            </div>
            <div
                v-if="showButtons"
                :class="$style.buttons"
            >
                <Button
                    v-for="(button, index) in content.buttons"
                    :key="index"
                    :class="$style.button"
                    size="l"
                    :link="button.link"
                    :visual="index === 0 ? 'fill' : 'outline'"
                    @click="button.onClick"
                >
                    {{ button.text }}
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import Button from '@/components_new/Button.vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useCategoriesStore } from '@/store/categories-store'
import { type ErrorStatus, type PageStatus } from '@/store/app-store'
import img404 from './assets/404.webp'
import imgJoystickOnFire from './assets/joystick-on-fire.webp'
import Typography from '../Typography.vue'

const categoriesStore = useCategoriesStore()

const { t } = useI18n()

const props = defineProps<{
    type: Exclude<PageStatus, 200>
    randomGameHRU: string | null
    showButtons?: boolean
}>()

const route = useRoute()

const contentForType = computed(() => ({
    404: {
        img: img404,
        imgAlt: t('404_imgAlt'),
        title: t('404_title'),
        text: t('404_text'),
        buttons: [
            {
                text: t('404_back'),
                link: '/',
                // onClick: () => {},
            },
            props.randomGameHRU && {
                text: t('404_randomGame'),
                link: `/game/${props.randomGameHRU}`,
                onClick: () => {
                    categoriesStore.updateRandomGame()
                },
            },
        ].filter((x) => !!x),
    },
    500: {
        img: imgJoystickOnFire,
        imgAlt: t('500_imgAlt'),
        title: t('500_title'),
        text: t('500_text'),
        buttons: [
            {
                text: t('500_reload'),
                link: route.fullPath,
                onClick: () => {},
            },
            {
                text: t('500_back'),
                link: '/',
                onClick: () => {},
            },
        ],
    },
}))

const content = computed(() => contentForType.value[props.type as ErrorStatus] || contentForType.value[500])
</script>
<style module>
.container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 40px;
}

.img {
    max-width: 60%;
    max-height: 160px;
}

.texts {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title {
    font-size: 28px;
    font-weight: 600;
    line-height: 100%;
}

.text {
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
}

.buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
.button {
    flex-grow: 1;
}
</style>
<i18n lang="json">
{
    "en": {
        "404_imgAlt": "Error not found",
        "404_title": "Level not found",
        "404_text": "Sorry, the page you requested does not exist on this site",
        "404_back": "Back to home",
        "404_randomGame": "Random game",
        "500_imgAlt": "Error something went wrong",
        "500_title": "Something went wrong",
        "500_text": "Try reloading page. We're working hard to fix it for you as soon as possible",
        "500_reload": "Reload page",
        "500_back": "Back to home"
    }
}
</i18n>
