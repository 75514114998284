<template>
    <component
        :is="props.is"
        :to="props.to"
        :target="props.target"
        :class="[
            $style.cardContainer,
            props.responsive && $style.responsive,
            props.appearance === 'big' && $style.bigCard,
        ]"
    >
        <img
            v-if="props.src"
            :alt="props.alt"
            :src="props.src"
            :srcset="srcset"
            :class="$style.img"
            itemprop="image"
            loading="lazy"
            width="1"
            height="1"
        />
        <slot />
    </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import BaseLink from '@/components_new/BaseLink.vue'

interface CardContainerProps {
    is?: 'div' | typeof BaseLink
    to?: string
    appearance: 'big' | 'medium'
    target?: '_blank' | '_self'
    src?: string
    alt?: string
    srcsetOn?: boolean
    responsive?: boolean
}

const props = withDefaults(defineProps<CardContainerProps>(), {
    is: 'div',
    srcsetOn: true,
})

function transformSrcSet(input?: string) {
    if (!input || !props.srcsetOn) {
        return ''
    }
    switch (props.appearance) {
        case 'big':
            return input
        case 'medium':
        default:
            return `${input.replace('enlarged', 'medium')},
            ${input} 2x`
    }
}

const srcset = computed(() => transformSrcSet(props.src))
</script>

<style module>
.cardContainer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-template-areas: 'main';
    width: 230px;
    aspect-ratio: var(--thumbnail-aspect-ratio);
    border-radius: 12px;
    overflow: hidden;
    background-color: var(--carbon-300);
}
.responsive {
    width: 100%;
    aspect-ratio: var(--thumbnail-aspect-ratio);
    height: auto;
}

.bigCard.responsive {
    --big-card-width: 320;
    --big-card-height: calc(320 / var(--thumbnail-aspect-ratio));

    aspect-ratio: calc(var(--big-card-width) + var(--cards-gap)) / calc(var(--big-card-height) + var(--cards-gap)); /* same as normal, but with additional 8px gaps */
}

.img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    grid-area: main;
}
</style>
