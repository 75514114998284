import { defineStore } from 'pinia'
import type {
    EventForLoggersType,
    CustomEventForGa,
    CustomPGEvents,
    PlaygamaLoggerEventType,
    AvailablePGEvents,
    AvailableGAEvents,
} from '@/types'
import { useUserStore } from '@/store/user-store/user-store'
import { useAppStore } from '@/store/app-store'
import { CUSTOM_PG_EVENTS } from '@/constants/general'
import { timer } from '@/utils/helpers'

const ANALYTICS_ENDPOINT = process.env.ANALYTICS_API_HOST
const ANALYTICS_PATH = `${ANALYTICS_ENDPOINT}/api/v1/events`
async function sendEvent(event: PlaygamaLoggerEventType) {
    try {
        await fetch(ANALYTICS_PATH, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(event),
            credentials: 'include',
        })
    } catch {
        // 🤷‍♂️
    }
}

export type EventData = {
    gameHru?: string
    clid?: string
    userId?: string
}

const KEY_EVENTS: { timeout: number; eventName: AvailablePGEvents }[] = [
    { timeout: 5 * 60 * 1000, eventName: 'timespent_5min' },
    { timeout: 10 * 60 * 1000, eventName: 'timespent_10min' },
    { timeout: 15 * 60 * 1000, eventName: 'timespent_15min' },
]

const checkIsGAEvent = (eventName: AvailablePGEvents): eventName is AvailableGAEvents =>
    !CUSTOM_PG_EVENTS.includes(eventName as CustomPGEvents)

const INITIAL_DELAY = 1000 // ms
let initialDelayPromise: null | Promise<void> = null
function intialDelay() {
    if (!initialDelayPromise) {
        initialDelayPromise = timer(INITIAL_DELAY)
    }
    return initialDelayPromise
}

export const useLoggerStore = defineStore('logger', () => {
    async function getEventData(): Promise<EventData> {
        const userStore = useUserStore()
        const appStore = useAppStore()
        const user = await userStore.getUser()
        return {
            clid: appStore.clid,
            userId: user?._id,
        }
    }

    async function logEvent(event: EventForLoggersType): Promise<void> {
        await intialDelay()
        const { clid, userId } = await getEventData()
        const { eventName } = event

        if (checkIsGAEvent(eventName)) {
            const gaEvent: CustomEventForGa = {
                event: event.event,
                eventName,
                label: event.label,
                action: event.action,
            }
            if (event.clid) {
                gaEvent.clid = event.clid
            }
            window.dataLayer.push(gaEvent)
        }

        const analyticsEvent: PlaygamaLoggerEventType = {
            eventName: event.eventName,
            pageName: event.pageName,
            gameHru: event.gameHru,
            userId,
            clid,
        }
        sendEvent(analyticsEvent)
    }

    function startKeyEventsCountdown() {
        KEY_EVENTS.forEach(({ timeout, eventName }) => {
            setTimeout(() => {
                logEvent({
                    event: 'custom_event',
                    eventName,
                    label: '',
                    action: '',
                })
            }, timeout)
        })
    }

    return {
        logEvent,
        startKeyEventsCountdown,
    }
})
