import type { CUSTOM_PG_EVENTS } from '@/constants/general'
import type { AdvAction, PageAdType, OutOfPageAdType } from '@/modules/adv'
import { MessageAction } from '@/modules/main-frame/message-types'

export enum GameProviders {
    GOOGLE_AD = 'googleAd',
    GAME_DISTRIBUTION = 'gameDistribution',
    YANDEX_AD = 'yandexAd',
    DEBUG_PROVIDER = 'debug',
    EMPTY_PROVIDER = 'emptyProvider',
}

export type TMessageAction = MessageAction & AdvAction

export type GameEvent = Omit<MessageEvent, 'data'> & {
    source: MessageEventSource
    data: {
        id: string
        action: TMessageAction
        payload?: Record<string, unknown>
        responseToId?: string
        type: 'adv' | 'liveness' | 'error'
    }
}

export type CustomPGEvents = (typeof CUSTOM_PG_EVENTS)[number]

export type AvailableGAEvents =
    | 'ad_show'
    | 'ad_request'
    | 'game_init'
    | 'navigation'
    | 'session_end'
    | 'ping'
    | 'click'
    | 'invite-friend'
    | 'sign-in'
    | 'login'
    | 'do-task'
    | 'claim'
    | 'timespent_5min'
    | 'timespent_10min'
    | 'timespent_15min'
    // TODO: check if events below need to go to GA (added this to fix TS)
    // and currently they are going to GA (if some are custom add them to CUSTOM_PG_EVENTS to filter them out)
    | 'navigate'
    | 'sign-out'
    | 'game-navigation'
    | 'share'
    | 'play'

export type AvailablePGEvents = AvailableGAEvents | CustomPGEvents

export type PlaygamaLoggerEventType = {
    userId?: string
    clid?: string
    eventName: AvailablePGEvents
    pageName?: string
    gameHru?: string
}

export type CustomEventForGa = {
    event: 'custom_event' | 'user_time'
    eventName: AvailableGAEvents
    label: OutOfPageAdType | PageAdType | number | string
    action: 'empty' | 'show' | 'start' | 'rewarded' | 'close' | 'init' | string
    clid?: 'direct' | string
}

export type EventForLoggersType = {
    clid?: CustomEventForGa['clid']
    event: CustomEventForGa['event']
    eventName: AvailablePGEvents
    label: CustomEventForGa['label']
    action: CustomEventForGa['action']
    pageName?: string
    gameHru?: string
}

export type PaginationType = {
    page: number
    pageSize: number
}

export type PlatformType = 'ios' | 'android' | 'desktop'

export type APIGameType = {
    id: string
    hru: string
    title: string
    icon: string
    preview: string
    description: string
    developer?: string
    how_to_play_text: string
    seo_text: string
    is_android: boolean
    is_ios: boolean
    is_desktop: boolean
    tags: string[]
    categories: string[]
    game_url: string
    screenshots: string[]
    videos: APIGameVideoType[]
    vertical: boolean
    horizontal: boolean
}

export type ShortGameType = {
    hru: string
    preview: string
    title: string
    video: string | undefined
}

export type ShortGameZipType = [string, string, string, string | undefined]

export type APIShortGameType = {
    hru: string
    icon: string
    id: string
    preview: string
    title: string
    videos: APIGameVideoType[]
}

export type APIGameVideoType = {
    player_url: string
    preview_url: string
    thumbnail_url: string
}

export type MainCategoriesType =
    | 'io'
    | 'action'
    | 'puzzle'
    | 'arcade'
    | 'sports'
    | 'strategy'
    | 'bubble-shooter'
    | 'girls'
    | 'boys'
    | 'car'
    | 'simulation'
    | 'shooting'
    | 'gun'
    | 'clicker'
    | 'funny'
    | 'snake'
    | 'skill'
    | 'drawing'
    | 'idle'
    | 'multiplayer'
    | 'solitaire'
    | 'horror'
    | 'two-player'
    | 'trending_now'
    | 'new'
    | 'random'

export type GameRecommendationType = 'recommended' | 'top_playgama' | 'related' | 'random'
export type APIGameRecommendation<T extends GameRecommendationType[]> = {
    [key in T[number]]: APIShortGameType[]
}

export type CategoryDataType = {
    categories: APICategoryData[]
    pagination: {
        page: 0
        pageSize: 0
    }
}

export type APICategoryDataType = {
    categories: APICategoryData[]
    pagination: {
        page: 0
        pageSize: 0
    }
}

export type APICategoryData = {
    id: string
    title: string
    games: APIShortGameType[]
    totalCount: number
}

export type MainCategoryListType = {
    id: MainCategoriesType
    title: string
    href: string
    games: APIShortGameType[]
}

export type APICategoryShortInfo = {
    id: string
    title: string
}

export type CategoryShortInfo = {
    id: string
    title: string
    href: string
}

export type APICategorySeoInfo = {
    id: string
    seoText: string
    description: string // full name of categories
    subCategories: { title: string; id: string }[]
    pathCategories: { title: string; id: string }[]
    metaTitle?: string
    metaDescription?: string
}

export type CategoryInfo = {
    id: string
    href: string
    fullTitle: string
    seoText: string
    metaTitle?: string
    metaDescription?: string
    subCategories: { title: string; id: string; href: string }[]
    pathCategories: { title: string; id: string; href: string }[]
}

export type FormSubmitData = {
    message: string
    email: string
    page?: string
}

export type UserSocialKeys = 'telegramAcc' | 'tonWallet'
export const SOCIALS = ['telegram', 'ton'] as const
export type SocialsName = (typeof SOCIALS)[number]
export type SocialType = 'account' | 'wallet'

export type UserSocialsType = {
    name: SocialsName
    account?: string
    type: SocialType
    tag: string
    key: UserSocialKeys
    isSupport: boolean
    validate: (value: string) => boolean
}

export type User = {
    _id: string
    firstName: string
    lastName: string
    avatar: string
    fennecs: number
    referralCode: string
    telegramAcc?: string
    tonWallet?: string
    gamesPlayed?: number
    createdAt?: number
    currentStreak?: number
    usersInvited?: number
}

export type TaskType =
    | 'VISIT_EVERYDAY'
    | 'REFERRAL_INVITE'
    | 'RANDOM_GAME'
    | 'PLAY_GAME'
    | 'REGISTER'
    | 'REFERRAL_REGISTER'
export type TaskStatus = 'ACTIVE' | 'COMPLETED' | 'CLAIMED'

export type BaseTaskDto = {
    task_id: string
    type: TaskType
    status: TaskStatus
    coins: number
}

export type DailyTaskDto = BaseTaskDto & { type: 'VISIT_EVERYDAY' }
export type RandomTaskDto = BaseTaskDto & {
    type: 'RANDOM_GAME'
    settings: {
        game: ShortGameType
        seconds_to_play: number
    }
}

export type GameTaskDto = BaseTaskDto & {
    type: 'PLAY_GAME'
    settings: {
        game: ShortGameType
        seconds_to_play: number
    }
}

export type ReferralTaskDto = BaseTaskDto & {
    type: 'REFERRAL_INVITE'
    settings: {
        invitations_not_claimed: number
    }
}

export type TaskDto = DailyTaskDto | RandomTaskDto | GameTaskDto | ReferralTaskDto

export type UserTasksStats = {
    wallet: {
        coins: number
    }
    tasks: TaskDto[]
}

export type AdditionalServerData = {
    conversionValue: number | undefined
    hasToken: boolean
}
